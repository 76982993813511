import { useCallback, useEffect, useState } from "react";
import { Col, Form } from "antd";

import { api } from "../../../../api";
import { IIdNamePair } from "../../../../utils/common-interfaces";
import { debounceMethod } from "../../../../utils/function-utils";
import CandidateSkillsForm from "../../../../pages/job-requisition/job-preview/candidate-shortlist-form/candidate-skills-form";
import { Select } from "../../../../components/form-fields";

const SkillEvaluation = () => {
	const [skillsOptions, setSkillsOptions] = useState<IIdNamePair[]>([]);
	const [isSkillsLoading, setIsSkillsLoading] = useState(false);

	useEffect(() => {
		getSkills("");
	}, []);

	const getSkills = (value: string) => {
		setIsSkillsLoading(true);
		api
			.get({
				path: `/skills?name=${value?.trim()?.toLowerCase() || ""}`,
				service: "job",
			})
			.then((response) => {
				const arr =
					response.data?.data?.filter(
						(el: IIdNamePair, index: number) =>
							response.data.data.findIndex((innerEl: IIdNamePair) => el.name === innerEl.name) ===
							index
					) || [];
				setSkillsOptions(arr);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => setIsSkillsLoading(false));
	};

	const skillSearch = useCallback(debounceMethod(getSkills), []);

	return (
		<>
			<Col sm={24} md={24}>
				<Form.Item
					label="Skills"
					name="skills"
					rules={[
						{
							required: true,
							message: "Please input skill!",
						},
					]}
				>
					<Select
						mode="tags"
						placeholder="Search and add your skills"
						options={skillsOptions?.map((el: IIdNamePair) => ({
							value: el.name,
							label: el.name,
						}))}
						onSearch={(val) => skillSearch(val)}
						disabled={isSkillsLoading}
						loading={isSkillsLoading}
					/>
				</Form.Item>
			</Col>
			<CandidateSkillsForm isSubmitFailed={false} />
		</>
	);
};

export default SkillEvaluation;
