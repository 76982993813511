import { useState, useMemo } from "react";
import { Divider, Form, message, Flex } from "antd";

import { InputNumber } from "../../../../components/form-fields/input-number";
import { api } from "../../../../api";
import { IGoalsDetail } from "../../../../constants/user-interfaces";
import { getApiResponseMsg, getApiErrorMsg } from "../../../../utils/object-util";
import { ReactComponent as Add } from "../../../../assets/images/plus-input-icon.svg";
import { ReactComponent as Substract } from "../../../../assets/images/minus-input-icon.svg";
import CustomButton from "../../../../components/button";

import styles from "../goals.module.scss";

interface IProps {
	goal: IGoalsDetail;
	onClose?: () => void;
}

const GoalDetails = ({ goal, onClose }: IProps) => {
	
	const getFieldName = useMemo(() => goal.title.toLowerCase().replace(/\s/g, ""), [goal.title]);

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const watchGoalAmount = Form.useWatch(getFieldName, form);

	const handleAdd = () => {
		form.setFieldsValue({ [getFieldName]: form.getFieldValue(getFieldName) + 1 });
		form.validateFields([getFieldName]);
	};

	const handleRemove = () => {
		const updatedValue = Math.max(form.getFieldValue(getFieldName) - 1, 1);
		form.setFieldsValue({ [getFieldName]: updatedValue });
		form.validateFields([getFieldName]);
	};

	const maxValueValidator = (value: number) => {
		const maxValue = goal.title === "Water Goals" ? 5 : 7;
		if (value < 1) {
			return Promise.reject(new Error("Minimum value is 1"));
		}
		if (value > maxValue) {
			return Promise.reject(new Error(`Maximum value is ${maxValue}`));
		}
		return Promise.resolve();
	};

	const handleTracking = () => {
		setLoading(true);
		api
			.put({
				path: `/goals/${goal.id}`,
				formdata: { amount: form.getFieldValue(getFieldName) },
				service: "job",
			})
			.then((response) => {
				message.success({
					content: getApiResponseMsg(response),
					key: "success",
					duration: 3,
				});
				if (onClose) onClose();
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
			})
			.finally(() => {
				setLoading(false);
				if (onclose) onClose;
			});
	};

	return (
		<>
			<div className={styles["wrapper-top"]}>
				<p className={styles["title"]}>{goal.title}</p>
				<p className={styles["content"]}>{goal.description}</p>
				<p className={styles["title"]}>Why {goal.type} Matters</p>
				<ul className={styles["content"]}>
					{goal.reasons.map((reason: any, index: any) => (
						<li key={goal?.id} className={styles["pointers"]}>
							<span className={styles["subtitle"]}>{reason.split(":")[0]}:</span>{" "}
							{reason.split(":")[1]}
						</li>
					))}
				</ul>
			</div>
			<Divider />
			<div className={styles["wrapper-content"]}>
				<p className={styles["title"]}>Set Your Goal:</p>
				<p className={styles["subtitle"]}>
					{goal.title === "Water Goals" ? "Daily Water Intake (liters)" : "Choose your target"}
				</p>
				<p className={styles["content"]}>Drag the slider to set your target:</p>
				<Form
					form={form}
					validateTrigger={["onChange,onBlur"]}
					initialValues={{ [getFieldName]: goal?.amount }}
				>
					<Form.Item
						name={[getFieldName]}
						rules={[{ validator: (_, value) => maxValueValidator(value) }]}
					>
						<Flex justify="flex-start" align="center" gap="8px">
							<InputNumber
								type="number"
								className={styles["input-number"]}
								value={watchGoalAmount}
								disabled
								addonBefore={
									<CustomButton icon={<Substract />} type="link" onClick={handleRemove} />
								}
								addonAfter={<CustomButton icon={<Add />} type="link" onClick={handleAdd} />}
							/>
							<span className={styles["goal-units"]}>
								{goal.title === "Water Goals" ? "litres" : "hours"}
							</span>
						</Flex>
					</Form.Item>
				</Form>
			</div>
			<Divider />
			<div className={styles["wrapper-bottom"]}>
				<p className={styles["title"]}>Tracking</p>
				<p className={styles["subtitle"]}>
					{goal.title === "Water Goals"
						? "Water Intake Tracker"
						: goal.title === "Distance Goals"
							? "Wearable Tracking"
							: "Activity Log"}
				</p>
				<p className={styles["content"]}>{goal.trackingInfo}</p>
				<div className={styles["button-wrapper"]}>
					<CustomButton
						type="default"
						className={styles["button"]}
						onClick={onClose}
						disabled={loading}
					>
						{goal.trackingCancelLabel}
					</CustomButton>
					<CustomButton
						type="primary"
						className={styles["button"]}
						onClick={handleTracking}
						loading={loading}
					>
						{goal.trackingButtonLabel}
					</CustomButton>
				</div>
			</div>
		</>
	);
};
export default GoalDetails;
