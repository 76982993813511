import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/lib";

import { AddSkills, Input, Select, TextArea } from "../../../../components/form-fields";
import Heading from "../../../../components/heading";
import CandidateEducationForm from "../../../../pages/job-requisition/job-preview/candidate-shortlist-form/candidate-education-form";
import { useCallback, useEffect, useState } from "react";
import { useCountryList } from "../../../../hooks";
import { IIdNamePair } from "../../../../utils/common-interfaces";
import { api } from "../../../../api";
import { debounceMethod } from "../../../../utils/function-utils";


interface IProps {
	form: FormInstance<any>;
}
const AboutYou = ({ form }: IProps) => {

	const [countries, setCountries] = useState<string[]>();
	const [states, setStates] = useState<Array<string>>([]);
	const [cityData, setCityData] = useState<Array<string>>([]);
	const [currentCompany, setCurrentCompany] = useState<IIdNamePair[]>([]);
	const { getCity, getCountries, getStates } = useCountryList();

	useEffect(() => {
		const country = form.getFieldValue(["address", "professional", "country"]);
		const state = form.getFieldValue(["address", "professional", "state"]);
		searchPreviousCompFunc("");
		handleCountryChange(country, true);
		handleStateChange(state, true);
	}, []);

	useEffect(() => {
      const getCountriesList = getCountries ? getCountries() : [];
      setCountries(getCountriesList);
  }, []);

	const searchPreviousCompFunc = (value: string) => {
		api
			.get({
				path: `/companies?name=${
					value?.trim()?.toLowerCase() || ""
				}`,
				service: "job",
			})
			.then((response) => {
				const arr =
					response.data?.data?.filter(
						(el: any, index: number) =>
							response.data.data.findIndex((innerEl: any) => el.name === innerEl.name) === index
					) || [];
				setCurrentCompany(arr);
			});
	};

	const searchPreviousCompany = useCallback(debounceMethod(searchPreviousCompFunc), []);


 const handleCountryChange = (val: string, trigger?: boolean) => {
	if (getStates) {
		const getStatesList = getStates(val);
		setStates(getStatesList);
	}

	if (!trigger) {
		form.setFieldValue(["address", "professional", "state"], undefined);
		form.setFieldValue(["address", "professional", "city"], undefined);
	}
	setCityData([]);
};

const handleStateChange = (val: string, trigger?: boolean) => {
	const getCounty = form.getFieldValue(["address", "professional", "country"]);
	if (getCity) {
		const getCityList = getCity(getCounty, val);
		setCityData(getCityList);
	}

	if (!trigger) {
		form.setFieldValue(["address", "professional", "city"], undefined);
	}
};
	return (
		<>
			<Heading title="Personal Preferences" />
			<Row gutter={20}>
				<Col span={24}>
					<Form.Item
						label="About Me"
						name="about_me"
						rules={[
							{
								message: "Please tell us about yourself!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Let us know something about yourself" />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item
						label="Favourite Music"
						name="favourite_music"
						rules={[
							{
								message: "Please enter your favourite music!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter your favourite music or genre" />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item
						label="Favourite TV Show"
						name="favourite_shows"
						rules={[
							{
								message: "Please enter your favourite TV show!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter your favourite TV show" />
					</Form.Item>
				</Col>
			</Row>

			<Heading title="Past Employment" />
			<Row gutter={20}>
				<Col sm={24} md={12}>
					<AddSkills
					label="Previous Company"
					name="company"
					options={currentCompany}
					onSearch={(val) => searchPreviousCompany(val)}
					isRequired
					apiUrl={`/companies`}
					isMultiple={false}
					requiredMessage="Please input current company!"
				/>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Job Title"
						name="job_title"
						rules={[
							{
								message: "Please enter your previous job title!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter your previous job title" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Address"
						name={["address", "professional", "address_line"]}
						rules={[
							{
								required: true,
								message: "Please input address!",
								whitespace: true,
							},
						]}
					>
						<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Country"
						name={["address", "professional", "country"]}
						rules={[
							{
								required: true,
								message: "Please select country!",
								whitespace: true,
							},
						]}
					>
						<Select
							options={countries?.map((el) => ({
								value: el,
								label: el,
							}))}
							onChange={(val) => handleCountryChange(val)} 
							placeholder="Select country"
						/>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="State"
						name={["address", "professional", "state"]}
						rules={[
							{
								required: states.length !== 0,
								message: "Please select state!",
								whitespace: true,
							},
						]}
					>
						<Select
							options={states?.map((el) => ({
								value: el,
								label: el,
							}))}
							onChange={(val) => handleStateChange(val)} 
							placeholder="Select state"
						/>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="City"
						name={["address", "professional", "city"]}
						rules={[
							{
								required: cityData.length !== 0,
								message: "Please select city!",
								whitespace: true,
							},
						]}
					>
						<Select
							options={cityData?.map((el) => ({
								value: el,
								label: el,
							}))}
							placeholder="Select city"
						/>
					</Form.Item>
				</Col>
			</Row>

			<Heading title="Educational Background" />
			<CandidateEducationForm initialFieldsCount={1} />
		</>
	);
};

export default AboutYou;
