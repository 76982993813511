import { useEffect, useState, useMemo,useCallback } from "react";
import dayjs from "dayjs";
import { Progress, Divider, message, Row, Col, Flex } from "antd";

import { api } from "../../api";
import { useCurrentUser } from "../../hooks";
import { ReactComponent as CustomIcon } from "../../assets/images/list-icon.svg";
import { ReactComponent as SleepIcon } from "../../assets/images/sleep-icon.svg";
import { ReactComponent as DropletIcon } from "../../assets/images/droplet.svg";
import { ReactComponent as HeartIcon } from "../../assets/images/heart.svg";
import { ReactComponent as DropIcon } from "../../assets/images/drop.svg";
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron-right.svg";
import { BaseCard } from "../../components/base-card";
import CustomButton from "../../components/button";
import { getApiErrorMsg } from "../../utils/object-util";
import { goalStrokeColor, goalsUnit, healthCards } from "../../constants/health-constants";
import Spinner from "../../components/spinner";
import { IBadgeEntry, IHealthData, IGoal } from "constants/user-interfaces";
import { convertKeyToLabel, formatNumber } from "../../utils/function-utils";

import Section from "./health-section";
import GoalCard from "./goals-card";
import WeekActivity from "./week-activity";
import CustomDrawer from "./custom-drawer";
import Goals from "./goals";
import KnowMore from "./knowmore";
import WaterLog from "./waterlog";
import PopUp from "./pop-up";
import GetStarted from "./get-started";
import HealthInsightCards from "./health-insight-cards";
import CustomizeToday from "./customizetoday";
import WaterLogCard from "./water-log-card";
import MyBadges from "./my-badges";
import HealthDetails from "./health-insights/health-details";
import styles from "./health.module.scss";

const Health = () => {
  const { user } = useCurrentUser();
  const [currentDay, setCurrentDay] = useState(dayjs());
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState<React.ReactNode>(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [data, setData] = useState<IHealthData>({} as IHealthData);
  const [loading, setLoading] = useState(true);
  const [badgesArray, setBadgesArray] = useState<IBadgeEntry[]>([]);
  const [fetchedGoals, setFetchedGoals] = useState<IGoal[]>();

  const openDrawer = useCallback(
    (content: React.ReactNode, title: string) => {
      setDrawerContent(content);
      setDrawerTitle(title);
      setDrawerVisible(true);
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setDrawerVisible(false);
    setDrawerContent(null);
    setDrawerTitle("");
  }, []);

  const handleSetData = useCallback(
    (newData: Record<string, any>) => {
      setData((prevData) => ({
        ...prevData,
        healthMetrics: prevData.healthMetrics?.map((metric: any) => ({
          ...metric,
          active: newData[metric.key] ?? metric.active,
        })),
      }));
    },
    []
  );

  const getGoalPropertyByType = useMemo(
    () => (goalType: string, property: keyof IGoal, defaultValue: any = null) => {
      const goal = fetchedGoals?.find((goal: IGoal) => goal.type === goalType);
      return goal ? goal[property] : defaultValue;
    },
    [fetchedGoals] 
  );

  const goals = useMemo(
    () => [
      {
        key: "Sleep",
        title: "Sleep Goals",
        trackingMetric: "Daily Sleep Hours",
        onClick: () =>
          openDrawer(
            <Goals
              title="Sleep Goals"
              onClose={closeDrawer}
              id={getGoalPropertyByType("Sleep", "id", "")}
            />,
            "Sleep Goals"
          ),
        IconComponent: SleepIcon,
      },
      {
        key: "Activity",
        title: "Activity Goals",
        trackingMetric: "Weekly Exercise Days",
        onClick: () =>
          openDrawer(
            <Goals
              title="Activity Goals"
              onClose={closeDrawer}
              id={getGoalPropertyByType("Activity", "id", "")}
            />,
            "Activity Goals"
          ),
        IconComponent: DropletIcon,
      },
      {
        key: "Distance",
        title: "Distance Goals",
        trackingMetric: "Track the total distance covered",
        onClick: () =>
          openDrawer(
            <Goals
              title="Distance Goals"
              onClose={closeDrawer}
              id={getGoalPropertyByType("Distance", "id", "")}
            />,
            "Distance Goals"
          ),
        IconComponent: HeartIcon,
      },
      {
        key: "Water",
        title: "Water Goals",
        trackingMetric: "Daily Water Intake",
        onClick: () =>
          openDrawer(
            <Goals
              title="Water Goals"
              onClose={closeDrawer}
              id={getGoalPropertyByType("Water", "id", "")}
            />,
            "Water Goals"
          ),
        IconComponent: DropIcon,
      },
    ],
    [openDrawer, closeDrawer, getGoalPropertyByType] 
  );

  const getBadges = useCallback(() => {
    api
      .get({
        path: "/fitbit-data/get-user-badges",
        service: "job",
      })
      .then((response) => {
        setBadgesArray(response.data.data);
      })
      .catch((error) => {
        message.error({
          content: getApiErrorMsg(error),
          key: "error",
          duration: 3,
        });
      });
  }, []);

  const getGoal = useCallback(() => {
    api
      .post({
        path: "/goals/all",
        service: "job",
      })
      .then((response) => {
        setFetchedGoals(response.data.data);
      })
      .catch((error) => {
        message.error({
          content: getApiErrorMsg(error),
          key: "error",
          duration: 3,
        });
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .post({
        path: "/fitbit-data/get-data",
        formdata: { date: currentDay.format("YYYY-MM-DD") },
        service: "job",
      })
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => {
        message.error({
          content: err?.response?.data?.message,
          key: "error",
          duration: 3,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentDay]);

  useEffect(() => {
    getBadges();
    getGoal();
  }, [getBadges, getGoal]);

	return loading ? (
		<Spinner />
	) : (
		<div className={styles["health-wrapper"]}>
			<PopUp
				connectedWithWatch={user?.sessions_data?.health?.fitbit_integrated}
				onClick={() => openDrawer(<GetStarted />, "Customize Today")}
			/>
			<div className={styles["goal-activity-container"]}>
				<div className={styles["container-wrapper"]}>
					<Section
						leftSideContent={
							<Flex gap={4} align="center">
								<p className={styles["day"]}>
									{currentDay.isSame(dayjs(), "day")
										? "Today"
										: currentDay.format("ddd, Do MMM YY")}
								</p>
								<Flex>
									<CustomButton
										icon={
											<ChevronLeft onClick={() => setCurrentDay(currentDay.subtract(1, "day"))} />
										}
										type="text"
									/>
									<CustomButton
										icon={<ChevronRight onClick={() => setCurrentDay(currentDay.add(1, "day"))} />}
										type="text"
										disabled={currentDay.isSame(dayjs(), "day")}
									/>
								</Flex>
							</Flex>
						}
						rightSideContent={
							<CustomButton
								type="link"
								onClick={() =>
									openDrawer(
										<CustomizeToday
											onClose={closeDrawer}
											data={data ?? undefined}
											setData={handleSetData}
										/>,
										"Customize Today"
									)
								}
								className={styles["custom-button"]}
							>
								<CustomIcon />
								Customize Today
							</CustomButton>
						}
						wrapperClassName={styles["header-wrapper"]}
					>
						<>
							{data?.healthMetrics
								?.filter((metric: any) => metric.active)
								.map((metric: any, index: number) => (
									<BaseCard key={metric.name} wrapperClassName={styles["card-wrapper"]}>
										<div>
											<p className={styles["card-text"]}>{convertKeyToLabel(metric.key)}</p>
											<p className={styles["card-score"]}>
												{formatNumber.format(metric.value)}
												{metric.goal > 0 ? (
													<span className={styles["progress-subtext"]}>
														{goalsUnit[metric.key] || ""}
													</span>
												) : (
													""
												)}
											</p>
										</div>
										{metric?.value >= 0 ? (
											<Progress
												type="circle"
												percent={(metric.value / metric.goal) * 100}
												showInfo={false}
												strokeWidth={20}
												size={40}
												strokeColor={goalStrokeColor[index % goalStrokeColor.length]}
											/>
										) : null}
									</BaseCard>
								))}
						</>
					</Section>

					<Section
						childrenStyle={{ flex: 1 }}
						leftSideHeading="30 Day Goals!"
						leftSideSubHeading="Challenge yourself to achieve these goals for 30 days to boost your health and
									fitness!"
					>
						<>
							{goals.map((goal) => (
								<GoalCard
									key={goal.key}
									title={goal.title}
									trackingMetric={goal.trackingMetric}
									progress={getGoalPropertyByType(goal.key, "streak", 0)}
									onClick={goal.onClick}
									IconComponent={goal.IconComponent}
									amount={getGoalPropertyByType(goal.key, "amount", 0)}
									isStreakStarted={getGoalPropertyByType(goal.key, "isStreakStarted", false)}
									streakDays={getGoalPropertyByType(goal.key, "streak", 0)}
									isStreakCompleted={getGoalPropertyByType(goal.key, "isCompleted", false)}
									getRewards={getGoalPropertyByType(goal.key, "gemReward", 0)}
								/>
							))}
						</>
					</Section>
				</div>

				<div className={styles["wrapper"]}>
					<WeekActivity currentDay={currentDay} weekly_activities={data?.weekly_activities ?? []} />
					{typeof data?.water_consumed_goal !== "undefined" && data.water_consumed_goal > 0 && (
						<WaterLogCard
							waterConsumed={data?.water_consumed ?? 0}
							waterConsumedGoal={data?.water_consumed_goal ?? 0}
							onClick={() => {
								openDrawer(
									<WaterLog
										waterConsumed={data?.water_consumed ?? 0}
										waterConsumedGoal={data?.water_consumed_goal ?? 0}
										onclose={(amount) => {
											closeDrawer();
											setData((prev) => {
												return {
													...prev,
													water_consumed: Number(prev.water_consumed) + Number(amount),
												};
											});
										}}
									/>,
									"Water Log"
								);
							}}
						/>
					)}
				</div>
			</div>

			<Section
				leftSideHeading="My Badges"
				rightSideContent={
					<CustomButton
						type="link"
						onClick={() => openDrawer(<KnowMore badgesArray={badgesArray} />, "Badges")}
						className={styles["custom-button"]}
					>
						Know More
					</CustomButton>
				}
			>
				<MyBadges badgesArray={badgesArray} />
			</Section>
			<Divider />
			<Section leftSideHeading="Health Insights" childrenClassName={styles["health-insights-card"]}>
				<Row gutter={[16, 16]}>
					{healthCards.map((item, index) => (
						<Col key={item.title} span={6}>
							<HealthInsightCards
								title={item.title}
								content={item.content}
								onClick={() => openDrawer(<HealthDetails title={item.title} />, item.title)}
							/>
						</Col>
					))}
				</Row>
			</Section>
			<CustomDrawer
				visible={drawerVisible}
				title={drawerTitle}
				onClose={closeDrawer}
				content={drawerContent}
				className={styles["custom-drawer"]}
			/>
		</div>
	);
};
export default Health;
