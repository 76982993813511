import { ReactNode } from "react";

import Spinner from "../../../components/spinner";

import styles from "./badges.module.scss";
interface IProps {
	name?: string;
	badge?: ReactNode;
	description?: string | number | null;
	wrapperClassName?: string;
	badgeClassName?: string;
	nameClassName?: string;
	descriptionClassName?: string;
	onClick?: () => void;
	loading?: boolean;
}

const Badges = ({
	name,
	badge,
	description,
	wrapperClassName,
	badgeClassName,
	nameClassName,
	descriptionClassName,
	loading,
	onClick,
}: IProps) => {
	return (
		<div className={`${styles["badgeContainer"]} ${wrapperClassName ?? ""}`} onClick={onClick}>
			<div className={`${styles["badge"]} ${badgeClassName ?? ""}`}>{badge}</div>
			{loading ? (
				<Spinner />
			) : (
				<>
					<p className={`${styles["name"]} ${nameClassName ?? ""}`}>{name}</p>
					<p className={`${styles["description"]} ${descriptionClassName ?? ""}`}>{description}</p>
				</>
			)}
		</div>
	);
};
export default Badges;
