import { BaseCard } from "../../../components/base-card";
import { ReactComponent as ColorHeart } from "../../../assets/images/heart 1.svg";
import EllipsesTooltip from "../../../components/tooltip";

import styles from "./health-insight-cards.module.scss";

interface IProps {
	showDrawer?: (content: string, title: string) => void;
	content?: string;
	title?: string;
	onClick?: () => void;
}

const HealthInsightCards = ({ content, title, onClick }: IProps) => {
	return (
		<BaseCard wrapperClassName={styles["insights-card-wrapper"]} onWrapperClick={onClick}>
			<p className={styles["insights-text"]}>
				<ColorHeart />
				<EllipsesTooltip text={title} className={styles["insights-title"]}>
					{title}
				</EllipsesTooltip>
			</p>
			<p className={styles["insights"]}>{content}</p>
		</BaseCard>
	);
};
export default HealthInsightCards;
