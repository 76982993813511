import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";

import LeftMenu from "../../left-menu";
import PrivateHeader from "../../private-header";
import { adminRoutes } from "../../../constants/routes";
import NestedMenuLayout from "../nested-menu-layout";

import styles from "./privateLayout.module.scss";
import { useCurrentUser } from "../../../hooks";

interface PrivateLayoutProps {
	children?: React.ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
	const { user } = useCurrentUser();
	const { pathname } = useLocation();
	const [menuCollapsed, setMenuCollapsed] = useState(
		localStorage.getItem("menuCollapsed") === "true"
	);

	const collapseCallback = (val: boolean) => {
		localStorage.setItem("menuCollapsed", JSON.stringify(val));
		setMenuCollapsed(val);
	};

	const findSubRoute = useMemo(
		() =>
			Object.values({ ...adminRoutes }).find(
				(route) => pathname.includes(route.route) && route.subRoutes
			),
		[pathname]
	);

	return (
		<>
			<PrivateHeader />
			<div className={styles["main-wrapper"]}>
				{((user?.team_member?.status !== "onboarding" &&
					user?.team_member?.status !== "PendingActivation") ||
					user?.is_super) && (
					<LeftMenu isCollapsed={!!menuCollapsed} collapseCallback={collapseCallback} />
				)}

				<div
					className={`${styles["content-layout"]} ${
						menuCollapsed ? styles["collapsed-content-layout"] : ""
					}`}
					style={{
						...(!(
							(user?.team_member?.status !== "onboarding" &&
								user?.team_member?.status !== "PendingActivation") ||
							user.is_super
						) && {
							margin: "0 auto",
						}),
					}}
				>
					<div className={styles["inner"]}>
						{findSubRoute ? (
							<NestedMenuLayout routes={findSubRoute}>{children}</NestedMenuLayout>
						) : (
							<>{children}</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivateLayout;
