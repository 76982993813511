import styles from "./customSteps.module.scss";

type Props = {
  text: string;
  activeStep: number;
  stepNumber: number;
};

const Step = ({ text, activeStep, stepNumber }: Props) => {
  return (
    <div
      className={`${styles["wrapper"]} ${
        stepNumber > activeStep && styles["faded"]
      }`}
    >
      <div
        className={`${styles["bar"]} ${
          stepNumber === activeStep && styles["active"]
        } ${stepNumber < activeStep && styles["prev"]}`}
      ></div>
      <div className={styles["number"]}>0{stepNumber + 1}</div>
      <div className={styles["text"]}>{text}</div>
    </div>
  );
};

export default Step;
