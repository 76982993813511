import styles from "./infoItem.module.scss";

interface IPropsBase {
	value: any;
	size?: "small" | "medium";
	highlightedColor?: string;
	headingClassName?: string;
	highlightedWidth?: number;
	headingStyle?: React.CSSProperties;
	valueClassName?: string;
	valueStyle?: React.CSSProperties;
	wrapperClassName?: string;
	wrapperStyle?: React.CSSProperties;
	children?: React.ReactNode;
}

type IPropsWithHeading = IPropsBase & {
	heading: string;
	withoutHeading?: false;
};

type IPropsWithoutHeading = IPropsBase & {
	withoutHeading: true;
};

type IProps = IPropsWithHeading | IPropsWithoutHeading;

const InfoItem = (props: IProps) => {
	const {
		value,
		size = "small",
		highlightedColor,
		highlightedWidth = 2,
		withoutHeading,
		headingClassName,
		valueClassName,
		wrapperClassName,
		wrapperStyle,
		headingStyle,
		valueStyle,
		children,
	} = props;

	const headingExists = "heading" in props;

	return (
		<div
			className={`${styles["info-wrapper"]} ${
				highlightedColor ? styles["highlighted"] : ""
			} ${wrapperClassName ?? ""}`}
			style={
				{
					"--highlightedWidth": highlightedWidth + "px",
					"--highlightedColor": highlightedColor,
					...wrapperStyle,
				} as React.CSSProperties
			}
		>
			{headingExists && !withoutHeading && (
				<p
					className={`${styles["heading"]} ${
						size === "small" ? styles["small"] : ""
					} ${size === "medium" ? styles["medium"] : ""} ${headingClassName ?? ""}`}
					style={headingStyle ?? {}}
				>
					{props.heading}
				</p>
			)}

			<p
				className={`${styles["value"]} ${
					size === "small" ? styles["small"] : ""
				} ${size === "medium" ? styles["medium"] : ""} ${valueClassName ?? ""}`}
				style={valueStyle ?? {}}
			>
				{value ?? "--"}
			</p>
			{children}
		</div>
	);
};

export default InfoItem;
