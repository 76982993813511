
import Step from "./step";
import styles from "./customSteps.module.scss";

interface IProps{
  steps : string[];
  activeStep:number;
  setActiveStep?:React.Dispatch<React.SetStateAction<number>>;
}
const CustomSteps = (props : IProps) => {
  const {steps, activeStep, setActiveStep} = props;
  return (
    <div className={styles.steps}>
				{steps.map((step, index) => (
					<Step key={step} text={step} activeStep={activeStep} stepNumber={index} />
				))}
			</div>
  )
}

export default CustomSteps