import { useState } from "react";
import dayjs from "dayjs";
import { message, Form } from "antd";

import { Input } from "../../../components/form-fields/input";
import { ReactComponent as WaterBottleIcon } from "../../../assets/images/waterBottle.svg";
import { ReactComponent as WaterGlassIcon } from "../../../assets/images/waterGlass.svg";
import Badges from "../badges";
import CustomButton from "../../../components/button";
import { api } from "./../../../api";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";
import WaterDropProgress from "../waterprogress";

import styles from "./waterlog.module.scss";

interface IProps {
	waterConsumed: number;
	waterConsumedGoal: number;
	onclose?: (amount: number) => void;
}

const WaterLog = ({ waterConsumed, waterConsumedGoal, onclose }: IProps) => {
	const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
	const [form] = Form.useForm();

	const handleAdd = ({ amount, type }: { amount: number; type: string }) => {
		const now = dayjs().format("YYYY-MM-DD");
		setLoading((prev) => ({ ...prev, [type]: true }));

		api
			.post({
				path: "/fitbit-data/log-water",
				formdata: { date: now, amount: amount },
				service: "job",
			})
			.then((response) => {
				message.success({
					content: getApiResponseMsg(response),
					key: "success",
					duration: 3,
				});
				if (onclose) onclose(amount);
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
			})
			.finally(() => {
				setLoading((prev) => ({ ...prev, [type]: false }));
			});
	};

	return (
		<div className={styles["log-water-wrapper"]}>
			<p className={styles["title"]}>Add water for today</p>
			<div className={styles["badges-wrapper"]}>
				<Badges
					badge={<WaterGlassIcon />}
					name="1 glass"
					description="(250 ml)"
					badgeClassName={styles["badges"]}
					onClick={() => handleAdd({ amount: 250, type: "glass" })}
					loading={loading.glass}
				/>
				<Badges
					badge={<WaterBottleIcon />}
					name="1 bottle"
					description="(500 ml)"
					badgeClassName={styles["badges"]}
					onClick={() => handleAdd({ amount: 500, type: "bottle" })}
					loading={loading.bottle}
				/>
				<Badges
					badge={<WaterBottleIcon />}
					name="1 large bottle"
					description="(750 ml)"
					badgeClassName={styles["badges"]}
					onClick={() => handleAdd({ amount: 750, type: "largeBottle" })}
					loading={loading.largeBottle}
				/>
			</div>
			<Form
				form={form}
				layout="vertical"
				requiredMark={false}
				onFinish={() =>
					handleAdd({
						amount: form.getFieldValue("amount"),
						type: "formSubmit",
					})
				}
				className={styles["form-wrapper"]}
			>
				<Form.Item
					label="Log Water(ml)"
					name="amount"
					style={{ width: "80%" }}
					rules={[
						{
							required: true,
							message: "Please enter a valid amount.",
						},
						{
							pattern: /^\d{1,6}$/,
							message: "Only numbers up to 6 digits are allowed.",
						},
					]}
				>
					<Input placeholder="How much did you drink?" type="number" min={1} max={999999} />
				</Form.Item>
				<CustomButton type="primary" htmlType="submit" loading={loading.formSubmit}>
					Log
				</CustomButton>
			</Form>

			<p className={styles["title"]}>Today</p>
			<div className={styles["progress-wrapper"]}>
				{waterConsumedGoal < 0 ? null : (
					<>
						<WaterDropProgress
							width={34}
							height={42}
					   progress={waterConsumed > 0 ? (waterConsumed / waterConsumedGoal): 0}
						/>
						<p className={styles["progress-done"]}>{waterConsumed > 0 ? waterConsumed : 0} ml</p>
						<p className={styles["progress-left"]}>
							{waterConsumedGoal - waterConsumed > 0 ? waterConsumedGoal - waterConsumed : 0}
							ml left
						</p>
					</>
				)}
			</div>
		</div>
	);
};

export default WaterLog;
