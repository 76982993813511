
export 	const healthCards = [
	{
		title: "Cardiovascular Fitness",
		content: "Measures the efficiency of your heart and lungs during activity",
	},
	{
		title: "Body Mass Index",
		content: "An indicator of body fat based on weight and height",
	},
	{
		title: "Blood Pressure",
		content: "The force of blood against the walls of your arteries",
	},
	{
		title: "Blood Glucose Levels",
		content: "Reflects how well your body manages blood sugar",
	},
	{
		title: "Cholesterol Levels",
		content: "The amount of cholesterol in your blood, important for heart health",
	},
	{
		title: "Vitamin D Levels",
		content: "Essential for bone health and immune function",
	},
	{
		title: "Iron Levels",
		content: "Crucial for red blood cell production and energy levels",
	},
	{
		title: "Sleep Quality",
		content: "Indicates how well you sleep and its impact on your health",
	},
];

 export const goalsUnit: { [key: string]: string } = {
	distance: "kms",
	time: "mins",
	steps: "",
	calories: "cal",
	sleep_duration: "",
	activity: "",
	floors: "",
	heart: "",
	active_zone_minutes: "",
};

export const healthInsightsData = [
	{
		title: "Cardiovascular Fitness",
		description:
			"Cardiovascular fitness measures the efficiency of your heart and lungs during physical activity. It’s a key indicator of overall health and endurance.",
		tracking: [
			{ subtitle: "Heart Rate", content: "Monitor your heart rate during and after exercise." },
			{
				subtitle: "VO2 Max",
				content: "Track your VO2 max, which indicates your body’s ability to utilize oxygen.",
			},
			{
				subtitle: "Endurance Exercises",
				content: "Engage in regular activities like running, cycling, or swimming.",
			},
		],
		riskSigns: [
			{
				subtitle: "High Resting Heart Rate",
				content: "Consistently high resting heart rate can indicate poor cardiovascular health.",
			},
			{
				subtitle: "Shortness of Breath",
				content:
					"Experiencing shortness of breath during light activities might suggest cardiovascular inefficiency.",
			},
		],
		tips: [
			{
				subtitle: "Regular Exercise",
				content:
					"Incorporate at least 150 minutes of moderate aerobic activity or 75 minutes of vigorous activity each week.",
			},
			{
				subtitle: "Gradual Progression",
				content: "Gradually increase the intensity and duration of your workouts.",
			},
			{
				subtitle: "Balanced Diet",
				content: "Maintain a balanced diet rich in fruits, vegetables, and lean proteins.",
			},
		],
		benefits: [
			{
				subtitle: "Increased Stamina",
				content: "Enhanced endurance and reduced fatigue during daily activities.",
			},
			{
				subtitle: "Heart Health",
				content: "Lower risk of heart disease, stroke, and high blood pressure.",
			},
			{
				subtitle: "Overall Well-being",
				content: "Improved mental health, better sleep, and more energy.",
			},
		],
		footer:
			"Remember: Regularly track your progress and consult with healthcare professionals to ensure your fitness regimen is safe and effective.",
	},
	{
		title: "Body Mass Index",
		description:
			"Body Mass Index (BMI) is an indicator of body fat based on your weight in relation to your height. It helps assess whether you are underweight, normal weight, overweight, or obese.",
		tracking: [
			{
				subtitle: "Weight Measurements",
				content: "Regularly weigh yourself using a reliable scale.",
			},
			{ subtitle: "Height Measurements", content: "Measure your height accurately." },
			{
				subtitle: "BMI Calculation",
				content:
					"Healthcare providers calculate BMI by using weight in kilograms (kg) divided by the square of height in meters (m2).",
			},
		],
		riskSigns: [
			{
				subtitle: "Overweight",
				content:
					"A BMI over 25 may indicate an increased risk of chronic diseases such as heart disease, diabetes, and hypertension.",
			},
			{
				subtitle: "Underweight",
				content:
					"A BMI under 18.5 may suggest nutritional deficiencies, weakened immune system, or other health issues.",
			},
		],
		tips: [
			{
				subtitle: "Balanced Diet",
				content: "Eat a balanced diet rich in fruits, vegetables, whole grains, and lean proteins.",
			},
			{
				subtitle: "Regular Exercise",
				content:
					"Engage in regular physical activity, combining both aerobic and strength-training exercises.",
			},
			{
				subtitle: "Monitor Changes",
				content:
					"Keep track of changes in your weight and BMI over time to identify trends and make necessary adjustments.",
			},
		],
		benefits: [
			{
				subtitle: "Reduced Disease Risk",
				content:
					"Lower risk of developing chronic diseases such as diabetes, heart disease, and certain cancers.",
			},
			{
				subtitle: "Improved Mobility",
				content: "Easier movement and reduced strain on joints and muscles.",
			},
			{
				subtitle: "Enhanced Well-being",
				content: "Better energy levels, improved mood, and higher self-esteem.",
			},
		],
		footer:
			"Remember: Regularly monitor your BMI and consult with healthcare professionals to create a personalized plan for maintaining a healthy weight.",
	},
	{
		title: "Blood Pressure",
		description:
			"Blood pressure is the force exerted by circulating blood against the walls of your arteries. It's crucial for maintaining adequate blood flow throughout your body.",
		tracking: [
			{
				subtitle: "Regular Monitoring",
				content: "Use a blood pressure monitor at home or get regular check-ups.",
			},
			{
				subtitle: "Systolic Pressure",
				content: "The top number, indicating the pressure in your arteries when your heart beats.",
			},
			{
				subtitle: "Diastolic Pressure",
				content:
					"The bottom number, indicating the pressure in your arteries when your heart rests between beats.",
			},
		],
		riskSigns: [
			{
				subtitle: "Hypertension",
				content:
					"Consistently high blood pressure (above 140/90 mmHg) increases the risk of heart disease, stroke, and kidney problems.",
			},
			{
				subtitle: "Hypotension",
				content:
					"Consistently low blood pressure (below 90/60 mmHg) can cause dizziness, fainting, and indicate underlying health issues.",
			},
		],
		tips: [
			{
				subtitle: "Balanced Diet",
				content:
					"Consume a diet low in salt, rich in fruits, vegetables, whole grains, and lean proteins.",
			},
			{
				subtitle: "Regular Exercise",
				content: "Engage in at least 150 minutes of moderate aerobic activity each week.",
			},
			{
				subtitle: "Stress Management",
				content: "Practice relaxation techniques such as deep breathing, meditation, and yoga.",
			},
			{
				subtitle: "Limit Alcohol and Avoid Smoking",
				content: "Reduce alcohol intake and quit smoking to improve overall cardiovascular health.",
			},
		],
		benefits: [
			{
				subtitle: "Heart Health",
				content: "Lower risk of heart attacks, strokes, and heart failure.",
			},
			{
				subtitle: "Kidney Protection",
				content: "Reduced risk of kidney damage and associated complications.",
			},
			{
				subtitle: "Overall Well-being",
				content: "Improved energy levels, reduced headaches, and better overall health.",
			},
		],
		footer:
			"Remember: Regularly monitor your blood pressure and consult with healthcare professionals to manage and maintain it within a healthy range.",
	},
	{
		title: "Blood Glucose Levels",
		description:
			"Blood glucose levels indicate the amount of sugar in your blood. Maintaining healthy levels is vital for energy regulation and preventing conditions like diabetes.",
		tracking: [
			{
				subtitle: "Regular Testing",
				content: "Use a blood glucose meter to check your levels, especially if you have diabetes.",
			},
			{
				subtitle: "Fasting Blood Sugar",
				content: "Measure your blood sugar after fasting for at least 8 hours.",
			},
			{
				subtitle: "Postprandial Blood Sugar",
				content: "Check your blood sugar 2 hours after eating.",
			},
		],
		riskSigns: [
			{
				subtitle: "Hyperglycemia",
				content:
					"High blood glucose levels (above 130 mg/dL fasting or 180 mg/dL post-meal) can lead to diabetes complications such as nerve damage, vision problems, and heart disease.",
			},
			{
				subtitle: "Hypoglycemia",
				content:
					"Low blood glucose levels (below 70 mg/dL) can cause dizziness, confusion, and fainting.",
			},
		],
		tips: [
			{
				subtitle: "Balanced Diet",
				content:
					"Eat a diet rich in fiber, whole grains, and lean proteins while limiting sugary and high-carbohydrate foods.",
			},
			{
				subtitle: "Regular Exercise",
				content:
					"Engage in at least 150 minutes of moderate physical activity each week to help regulate blood sugar levels.",
			},
			{
				subtitle: "Consistent Meal Timing",
				content:
					"Eat meals and snacks at regular intervals to prevent spikes and dips in blood glucose.",
			},
			{
				subtitle: "Medication Adherence",
				content:
					"Take medications as prescribed if you have diabetes or other conditions affecting blood glucose levels.",
			},
		],
		benefits: [
			{
				subtitle: "Energy Stability",
				content: "Consistent energy levels throughout the day without extreme highs and lows.",
			},
			{
				subtitle: "Reduced Complications",
				content:
					"Lower risk of developing diabetes-related complications such as neuropathy, retinopathy, and cardiovascular disease.",
			},
			{
				subtitle: "Overall Health",
				content: "Improved mood, better sleep, and enhanced physical well-being.",
			},
		],
		footer:
			"Remember: Regularly monitor your blood glucose levels and consult with healthcare professionals to manage and maintain them within a healthy range.",
	},
	{
		title: "Cholesterol Levels",
		description:
			"Cholesterol is a fatty substance in your blood that is essential for building cells, but high levels can increase the risk of heart disease and stroke.",
		tracking: [
			{
				subtitle: "Lipid Profile Test",
				content:
					"Periodic blood tests to measure HDL (good cholesterol), LDL (bad cholesterol), and total cholesterol.",
			},
			{
				subtitle: "HDL (High-Density Lipoprotein)",
				content: "Helps remove excess cholesterol from your bloodstream.",
			},
			{
				subtitle: "LDL (Low-Density Lipoprotein)",
				content: "Can build up in your arteries, leading to atherosclerosis.",
			},
		],
		riskSigns: [
			{
				subtitle: "High LDL Levels",
				content:
					"Increased risk of plaque buildup in arteries, leading to heart disease and stroke.",
			},
			{
				subtitle: "Low HDL Levels",
				content:
					"Less removal of cholesterol from the bloodstream, increasing cardiovascular risk.",
			},
		],
		tips: [
			{
				subtitle: "Healthy Diet",
				content:
					"Eat foods rich in omega-3 fatty acids, soluble fiber, and healthy fats while avoiding trans fats and saturated fats.",
			},
			{
				subtitle: "Regular Exercise",
				content:
					"Engage in at least 150 minutes of moderate aerobic activity each week to boost HDL levels.",
			},
			{
				subtitle: "Weight Management",
				content: "Maintain a healthy weight to help control cholesterol levels.",
			},
			{
				subtitle: "Avoid Smoking and Limit Alcohol",
				content:
					"Smoking lowers HDL levels, while excessive alcohol can raise total cholesterol levels.",
			},
		],
		benefits: [
			{
				subtitle: "Heart Health",
				content: "Reduced risk of heart attacks, strokes, and other cardiovascular diseases.",
			},
			{
				subtitle: "Artery Health",
				content: "Prevents the buildup of plaque in the arteries, ensuring better blood flow.",
			},
			{
				subtitle: "Overall Well-being",
				content:
					"Improved energy levels, better physical fitness, and lower risk of chronic diseases.",
			},
		],
		footer:
			"Remember: Regularly monitor your cholesterol levels and consult with healthcare professionals to create a personalized plan for maintaining healthy levels.",
	},
	{
		title: "Vitamin D Levels",
		description:
			"Vitamin D is essential for bone health, immune function, and overall well-being. It helps your body absorb calcium and phosphorus, which are crucial for maintaining strong bones and teeth.",
		tracking: [
			{
				subtitle: "Blood Tests",
				content: "Regular blood tests to measure vitamin D levels (25-hydroxyvitamin D).",
			},
			{
				subtitle: "Sun Exposure",
				content:
					"Track your time spent in sunlight, as the skin produces vitamin D when exposed to UV rays.",
			},
			{
				subtitle: "Diet and Supplements",
				content: "Monitor your intake of vitamin D-rich foods and supplements.",
			},
		],
		riskSigns: [
			{
				subtitle: "Deficiency",
				content:
					"Low levels of vitamin D can lead to bone pain, muscle weakness, and an increased risk of fractures.",
			},
			{
				subtitle: "Excess",
				content:
					"Extremely high levels can cause calcium buildup, leading to heart and kidney problems.",
			},
		],
		tips: [
			{
				subtitle: "Sun Exposure",
				content:
					"Aim for 10-30 minutes of midday sun exposure several times a week, depending on your skin type and location.",
			},
			{
				subtitle: "Diet",
				content:
					"Consume foods rich in vitamin D, such as fatty fish, fortified dairy products, and egg yolks.",
			},
			{
				subtitle: "Supplements",
				content:
					"Consider taking vitamin D supplements, especially during the winter months or if you have limited sun exposure.",
			},
		],
		benefits: [
			{
				subtitle: "Bone Health",
				content: "Stronger bones and teeth, reduced risk of osteoporosis and fractures.",
			},
			{
				subtitle: "Immune Support",
				content: "Enhanced immune function, helping your body fight off infections.",
			},
			{
				subtitle: "Mood and Mental Health",
				content: "Improved mood and reduced risk of depression.",
			},
		],
		footer:
			"Remember: Regularly monitor your vitamin D levels and consult with healthcare professionals to ensure they remain within a healthy range.",
	},
	{
		title: "Iron Levels",
		description:
			"Iron is crucial for producing hemoglobin, a protein in red blood cells that carries oxygen throughout your body. Maintaining adequate iron levels is essential for energy, immune function, and overall health.",
		tracking: [
			{
				subtitle: "Blood Tests",
				content: "Regular blood tests to measure hemoglobin and ferritin levels.",
			},
			{
				subtitle: "Diet",
				content:
					"Monitor your intake of iron-rich foods, such as red meat, leafy greens, and fortified cereals.",
			},
			{ subtitle: "Symptoms", content: "Pay attention to symptoms of low or high iron levels." },
		],
		riskSigns: [
			{
				subtitle: "Deficiency (Anemia)",
				content:
					"Symptoms include fatigue, pale skin, shortness of breath, and frequent infections.",
			},
			{
				subtitle: "Excess (Hemochromatosis)",
				content: "Symptoms include joint pain, abdominal pain, fatigue, and liver damage.",
			},
		],
		tips: [
			{
				subtitle: "Balanced Diet",
				content:
					"Eat a variety of iron-rich foods, combining them with vitamin C sources to enhance absorption.",
			},
			{
				subtitle: "Avoid Excessive Iron Supplements",
				content: "Only take iron supplements if prescribed by a healthcare professional.",
			},
			{
				subtitle: "Regular Check-ups",
				content:
					"Get regular blood tests to monitor your iron levels, especially if you have conditions that affect iron absorption.",
			},
		],
		benefits: [
			{ subtitle: "Energy and Stamina", content: "Improved energy levels and reduced fatigue." },
			{ subtitle: "Better Immune Function", content: "Enhanced ability to fight infections." },
			{
				subtitle: "Overall Health",
				content: "Better cognitive function, mood stability, and physical performance.",
			},
		],
		footer:
			"Remember: Regularly monitor your iron levels and consult with healthcare professionals to manage and maintain them within a healthy range.",
	},
	{
		title: "Sleep Quality",
		description:
			"Sleep quality refers to how well you sleep, including how long it takes to fall asleep, how often you wake up during the night, and how refreshed you feel in the morning. Good sleep is essential for physical and mental health.",
		tracking: [
			{
				subtitle: "Sleep Duration",
				content: "Use fitness trackers or sleep apps to monitor how long you sleep each night.",
			},
			{
				subtitle: "Sleep Stages",
				content: "Track the time spent in different sleep stages (light, deep, and REM sleep).",
			},
			{
				subtitle: "Sleep Interruptions",
				content: "Record the number of times you wake up during the night.",
			},
		],
		riskSigns: [
			{ subtitle: "Insomnia", content: "Difficulty falling asleep or staying asleep." },
			{ subtitle: "Sleep Apnea", content: "Frequent waking due to breathing interruptions." },
			{
				subtitle: "Poor Sleep Quality",
				content: "Feeling tired despite spending enough time in bed.",
			},
		],
		tips: [
			{
				subtitle: "Consistent Schedule",
				content: "Go to bed and wake up at the same time every day, even on weekends.",
			},
			{
				subtitle: "Sleep Environment",
				content: "Create a comfortable sleep environment with a cool, dark, and quiet room.",
			},
			{
				subtitle: "Relaxation Techniques",
				content:
					"Practice relaxation techniques such as deep breathing, meditation, or reading before bed.",
			},
			{
				subtitle: "Limit Stimulants",
				content: "Avoid caffeine and electronic screens close to bedtime.",
			},
		],
		benefits: [
			{
				subtitle: "Enhanced Cognitive Function",
				content: "Improved concentration, memory, and problem-solving skills.",
			},
			{
				subtitle: "Emotional Well-being",
				content: "Better mood regulation and reduced stress levels.",
			},
			{
				subtitle: "Physical Health",
				content: "Stronger immune system, better heart health, and optimal physical performance.",
			},
		],
		footer:
			"Remember: Regularly monitor your sleep quality and consult with healthcare professionals if you experience persistent sleep issues. Good sleep is fundamental to overall health and well-being.",
	},
];


export const goalsData = [
	{
		title: "Sleep Goals",
		description:
			"A good night's sleep is the cornerstone of a healthy, happy life. Ready to wake up refreshed and energized every day? Let’s set your sleep goals and start your journey to better rest!",
		reasons: [
			"Boosts Mood: Say goodbye to crankiness and hello to positivity!",
			"Improves Focus: Sharpen your mind and ace your day.",
			"Enhances Health: Strengthen your immune system and overall well-being.",
		],
		trackingInfo:
			"Your fitness tracker will automatically sync your sleep data. Make sure your tracker is connected!",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Sleep",
	},
	{
		title: "Activity Goals",
		description:
			"Get ready to move your body and feel fantastic! Setting activity goals will boost your energy and keep you strong and healthy.",
		reasons: [
			"Energy Boost: Feel more energized throughout your day.",
			"Strengthens Muscles: Get stronger and more toned.",
			"Improves Mood: Exercise releases those feel-good endorphins!",
		],
		trackingInfo:
			"Log your exercise sessions to keep track of your progress. Your fitness tracker will automatically sync your data.",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Activity",
	},
	{
		title: "Distance Goals",
		description:
			"Get ready to challenge yourself and explore new distances. Setting distance goals will keep you on your feet and push your endurance to new levels",
		reasons: [
			"Increased Stamina: Build endurance with each step you take.",
			"Cardio Health: Keep your heart healthy by staying active.",
			"Weight Management: Burn calories and maintain a healthy weight.",
		],
		trackingInfo:"Your wearable device will automatically track the distance you walk, run, or cycle, giving you real-time feedback on your progress.",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Distance",
	},
	{
		title: "Water Goals",
		description:
			"Water is essential for health! Set your water intake goals and stay hydrated throughout the day.",
		reasons: [
			"Hydrates Your Body: Water is essential for all bodily functions.",
			"Improves Skin Health: Stay hydrated for glowing skin.",
			"Regulates Body Temperature: Helps maintain a stable body temperature.",
		],
		trackingInfo:
			"Track your daily water intake to ensure you meet your hydration goals. Your fitness tracker will automatically sync your data.",
		trackingButtonLabel: "Start Tracking",
		trackingCancelLabel: "Cancel",
		type: "Water",
	},
];

export const goalStrokeColor = [
	{
		"0%": "#7E07E0",
		"100%": "#AE66FF",
	},
	{
		"0%": "#84C400",
		"100%": "#D9E242",
	},
	{
		"0%": "#F9B800",
		"100%": "#FDE046",
	},
	{
		"0%": "#40A7E8",
		"100%": "#59CBF2",
	},
];