import { useState } from "react";
import { message } from "antd";

import { api } from "../../../api";
import { ReactComponent as FitbitIcon } from "../../../assets/images/FitIcon.svg";
import { ReactComponent as AppleIcon } from "../../../assets/images/apple-watch.svg";
import { ReactComponent as AmazefitIcon } from "../../../assets/images/AmazefitIcon.svg";
import { ReactComponent as XiaomiIcon } from "../../../assets/images/XiaomiIcon.svg";
import { ReactComponent as GarminIcon } from "../../../assets/images/GarminIcon.svg";
import { ReactComponent as SamsungIcon } from "../../../assets/images/SamsungIcon.svg";
import { BaseCard } from "../../../components/base-card";
import CustomButton from "../../../components/button";

import styles from "./getStarted.module.scss";

const GetStarted = () => {
	const [isActive, setisActive] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	const handleClick = (value: string) => {
		if (isActive === value) {
			setisActive("");
		} else {
			setisActive(value);
		}
	};

	const handleContinue = () => {
		setLoading(true);
		api
			.get({ path: "/fitbit/auth-url", service: "job" })
			.then((resp) => {
				window.location.href = resp.data.url;
			})
			.catch((err) => {
				message.error({
					content: err?.response?.data?.message,
					key: "error",
					duration: 3,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={styles["started-wrapper"]}>
			<p className={styles["title"]}>Choose your tracker</p>
			<p className={styles["subtitle"]}>
				Connecting your fitness tracker allows us to provide you with personalized insights and
				track your progress effortlessly. Select your tracker below to get started.
			</p>
			<BaseCard
				wrapperClassName={`${styles["wrapper"]} ${isActive === "fitbit" ? styles["active"] : ""}`}
				onWrapperClick={() => handleClick("fitbit")}
			>
				<FitbitIcon />
				<p className={styles["card-text"]}>Fitbit</p>
			</BaseCard>
			<BaseCard
				wrapperClassName={`${styles["wrapper"]} ${styles["base-card-disabled"]} ${null ? styles["active"] : ""}`}
				// onWrapperClick={handleClick}
			>
				<AppleIcon />
				<p className={styles["card-text"]}>Apple Watch</p>
			</BaseCard>
			<BaseCard
				wrapperClassName={`${styles["wrapper"]} ${styles["base-card-disabled"]} ${null ? styles["active"] : ""}`}
			>
				<AmazefitIcon />
				<p className={styles["card-text"]}>Amaze Fit</p>
			</BaseCard>
			<BaseCard
				wrapperClassName={`${styles["wrapper"]} ${styles["base-card-disabled"]} ${null ? styles["active"] : ""}`}
			>
				<XiaomiIcon />
				<p className={styles["card-text"]}>Xiaomi Mi Band</p>
			</BaseCard>
			<BaseCard
				wrapperClassName={`${styles["wrapper"]} ${styles["base-card-disabled"]} ${null ? styles["active"] : ""}`}
			>
				<GarminIcon />
				<p className={styles["card-text"]}>Garmin</p>
			</BaseCard>
			<BaseCard
				wrapperClassName={`${styles["wrapper"]} ${styles["base-card-disabled"]} ${null ? styles["active"] : ""}`}
			>
				<SamsungIcon />
				<p className={styles["card-text"]}>Samsung Galaxy Watch</p>
			</BaseCard>
			<div className={styles["button-wrapper"]}>
				<CustomButton type="default" className={styles["buttons"]}>
					Cancel
				</CustomButton>
				<CustomButton
					type="primary"
					className={styles["buttons"]}
					onClick={handleContinue}
					disabled={!isActive}
					loading={loading}
				>
					Continue
				</CustomButton>
			</div>
		</div>
	);
};
export default GetStarted;
