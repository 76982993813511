import { Checkbox as AntdCheckbox } from "antd";
import { CheckboxGroupProps, CheckboxProps } from "antd/lib/checkbox";

import styles from "./checkbox.module.scss";

interface ICheckboxProps extends CheckboxProps {
  isCustomThemed?: boolean;
  hideCheckBox?:boolean
}
interface ICheckboxGroupProps extends CheckboxGroupProps {
  isCustomThemed?: boolean;
}

export const Checkbox = ({
  children,
  isCustomThemed = false,
  hideCheckBox=false,
  className,
  ...rest
}: ICheckboxProps) => {
  return (
    <AntdCheckbox
      className={` ${
        isCustomThemed ? styles["custom-checkbox"] : styles["checkbox"]
      }
       ${className ?? ""} ${hideCheckBox?styles["hide-checkbox"]:""}`}
      {...rest}
    >
      {children}
    </AntdCheckbox>
  );
};

const Group = ({
  isCustomThemed = false,
  className,
  ...rest
}: ICheckboxGroupProps) => {
  return (
    <AntdCheckbox.Group
      className={` ${
        isCustomThemed ? styles["custom-checkbox"] : styles["checkbox"]
      }
       ${className ?? ""}`}
      {...rest}
    />
  );
};

Checkbox.Group = Group;
