import { useEffect, useState } from "react";
import { message } from "antd";

import { api } from "../../../api";
import { getApiErrorMsg } from "../../../utils/object-util";
import { IGoalsDetail } from "../../../constants/user-interfaces";
import { goalsData } from "../../../constants/health-constants";
import Spinner from "../../../components/spinner";

import GoalDetails from "./goal-details";

interface goalsProp {
	id?: string;
	title: string;
	onClose?: () => void;
}

const Goals = ({ title, onClose, id }: goalsProp) => {
	const [data, setData] = useState<IGoalsDetail | null>();

	useEffect(() => {
		api
			.get({
				path: `/goals/${id}`,
				service: "job",
			})
			.then((response) => {
				setData(response.data.data.amount);
				if (response?.data?.data && response?.data?.data?.amount !== undefined) {
					const matchingGoal = goalsData.find((goal) => goal.type === response.data.data.type);

					if (matchingGoal) {
						setData({
							...matchingGoal,
							id: id ?? "",
							amount: response.data.data.amount ?? 0,
						});
					}
				}
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
			});
	}, [title, id]);

	return (
		<div>{data && data?.id ? <GoalDetails goal={data} onClose={onClose} /> : <Spinner />}</div>
	);
};
export default Goals;
