import { useEffect, useState } from "react";
import { Col, Form, FormInstance, message, Row, Upload } from "antd";
import { UploadProps } from "antd/lib";
import { RcFile } from "antd/es/upload";

import { api } from "../../../../api";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload-cloud.svg";
import Heading from "../../../../components/heading";
import UploadedFile from "../../../../components/uploaded-file";
import Spinner from "../../../../components/spinner";

export interface IUserDocs {
	name: string;
	url: string;
	id?: string;
}

const DocumentsUploadForm = ({
	form,
	hideTitle = false,
	previousFiles,
	setPreviousFiles,
}: {
	form: FormInstance<any>;
	hideTitle?: boolean;
	previousFiles?: IUserDocs[];
	setPreviousFiles?: React.Dispatch<React.SetStateAction<IUserDocs[]>>;
}) => {
	const [uploadingFiles, setUploadingFiles] = useState<{ [key: string]: boolean }>({});
	const [uploadedFiles, setUploadedFiles] = useState<IUserDocs[]>([]);

	useEffect(() => {
		if (previousFiles) {
			setUploadedFiles(previousFiles);
		}
	}, [previousFiles]);

	const fileTypes: {
		name: string;
		required: boolean;
		description: string;
	}[] = [
		{
			name: "ID Proof",
			required: true,
			description: "Aadhaar card, Passport, PAN card etc",
		},
		{
			name: "Address Proof",
			required: true,
			description: "Aadhaar card, Utility bills, Passport etc",
		},
		{
			name: "Highest Education Certificate",
			required: true,
			description: "Higher Secondary, Graduation, Diploma etc",
		},
		{
			name: "Previous Employment Letter",
			required: false,
			description: "if applicable",
		},
		{
			name: "Last Pay Slip",
			required: false,
			description: "if applicable",
		},
	];

	const uploadFile = (file: RcFile, fileTypeName: string) => {
		if (file.size >= 2000000) {
			message.error("File too large! Please select a file under 2MB.");
		} else {
			const formData = new FormData();
			formData.append("file", file);

			setUploadingFiles((prev) => ({ ...prev, [fileTypeName]: true }));

			api
				.post({
					path: "/upload",
					service: "job",
					formdata: formData,
				})
				.then((response) => {
					const uploadedUrl = response.data?.data?.file;
					if (uploadedUrl) {
						handleFileChange(fileTypeName, uploadedUrl);
					}
				})
				.catch((err) => {
					message.error(`Upload failed: ${err.message}`);
				})
				.finally(() => setUploadingFiles((prev) => ({ ...prev, [fileTypeName]: false })));
		}
	};

	const getUploadProps = (fileTypeName: string): UploadProps => ({
		name: "file",
		showUploadList: false,
		accept: ".pdf,.png,.doc,.jpeg",
		beforeUpload: (file: RcFile) => {
			const isValidFile =
				file.type === "image/png" ||
				file.type === "image/jpeg" ||
				file.type === "application/pdf" ||
				file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

			if (!isValidFile) {
				message.error(`${file.name} is not allowed`);
				return false;
			}
			uploadFile(file, fileTypeName);
			return false;
		},
	});

	const handleFileChange = (fileTypeName: string, fileUrl: string) => {
		const newFileData = { name: fileTypeName, url: fileUrl };

		const existingFile = uploadedFiles.find((file) => file.name === fileTypeName);
		if (existingFile) {
			setUploadedFiles((prev) => {
				const newArr = [...prev];
				const index = newArr.findIndex((file) => file.name === fileTypeName);
				newArr[index] = newFileData;
				return newArr;
			});
		} else {
			setUploadedFiles((prev) => [...(prev ?? []), newFileData]);
		}

		form.setFieldsValue({
			[fileTypeName]: newFileData.url,
		});
	};

	const handleRemoveFile = (fileName: string) => {
		setUploadedFiles((prev) => prev.filter((file) => file.name !== fileName));
	};

	const findUploadedFileUrl = (fileName: string) => {
		const uploadedFile = uploadedFiles.find((file) => file.name === fileName);
		return uploadedFile?.url;
	};

	return (
		<>
			{!hideTitle && <Heading title="New Joinee Documents" />}

			<Row gutter={20}>
				{fileTypes.map((file, index) => (
					<Col key={index} xs={24} sm={12} md={8}>
						<Form.Item
							label={file.name}
							name={file.name}
							rules={[
								{
									required: file.required,
									message: `Please upload your ${file.name.toLowerCase()}!`,
								},
							]}
							extra={
								uploadingFiles[file.name] || findUploadedFileUrl(file.name) ? "" : file.description
							}
						>
							{uploadingFiles[file.name] ? (
								<Spinner />
							) : findUploadedFileUrl(file.name) ? (
								<UploadedFile
									url={findUploadedFileUrl(file.name) ?? ""}
									onRemove={() => handleRemoveFile(file.name)}
									icon
									wrapperStyle={{ height: "100%" }}
									size="large"
								/>
							) : (
								<Upload.Dragger
									name="file"
									multiple={false}
									{...getUploadProps(file.name)}
									style={{ display: "flex", alignItems: "center" }}
								>
									<UploadIcon />
									<p style={{ color: "#8c8c8c", fontWeight: "500" }}>
										Click or drag file to upload
									</p>
								</Upload.Dragger>
							)}
						</Form.Item>
					</Col>
				))}
			</Row>
		</>
	);
};

export default DocumentsUploadForm;
