import { useEffect, useState } from "react";
import { Form, message } from "antd";

import { Switch } from "../../../components/form-fields/switch";
import { api } from "../../../api";
import { IHealthData, IHealthMetric } from "../../../constants/user-interfaces";
import { convertKeyToLabel, formatNumber } from "../../../utils/function-utils";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";
import CustomButton from "../../../components/button";

import styles from "./customizetoday.module.scss";

interface IProps {
	onClose: () => void;
	data?: IHealthData;
	setData: (data: any) => void;
}

const CustomizeToday = ({ onClose, data, setData }: IProps) => {
	const [form] = Form.useForm();
	const [disabledSwitches, setDisabledSwitches] = useState<Record<string, boolean>>({});
	const [loading, setLoading] = useState(false);
	const switches = Form.useWatch([], form);

	useEffect(() => {
		if (data?.healthMetrics) {
			const activeCount = data.healthMetrics.filter((metric) => metric.active).length;
			const initialDisabledSwitches = data.healthMetrics.reduce(
				(acc, { key, active }) => {
					acc[key] = activeCount >= 4 && !active;
					return acc;
				},
				{} as Record<string, boolean>
			);
			setDisabledSwitches(initialDisabledSwitches);
		}
	}, [data]);

	const handleSwitchChange = () => {
		const activeCount = Object.values(form.getFieldsValue()).filter(Boolean).length;
		const newDisabledSwitches = data?.healthMetrics.reduce(
			(acc, { key }) => {
				acc[key] = activeCount >= 4 && !form.getFieldValue(key);
				return acc;
			},
			{} as Record<string, boolean>
		);
		setDisabledSwitches(newDisabledSwitches || {});
	};

	const handleSave = () => {
		setLoading(true);
		api
			.put({
				path: "/health-metrics",
				formdata: switches,
				service: "job",
			})
			.then((response) => {
				setData(response.data.data);

				message.success({
					content: getApiResponseMsg(response),
					key: "success",
					duration: 3,
				});

				if (onClose) onClose();
				setLoading(false);
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "error",
					duration: 3,
				});
				setLoading(false);
			});
	};

	return (
		<div className={styles["customize-today-wrapper"]}>
			<p className={styles["drawer-subtitle"]}>Show/Hide Metrics</p>
			<Form form={form} onFinish={handleSave} onValuesChange={handleSwitchChange}>
				<div className={styles["metrics-wrapper"]}>
					{data?.healthMetrics.map((metric: IHealthMetric) => (
						<div key={metric.key} className={styles["metrics"]}>
							<span>
								{convertKeyToLabel(metric.key)}
								<p className={styles["values"]}>
									{formatNumber.format(metric.value)} of{" "}
									{metric.goal > 0 ? formatNumber.format(metric.goal) : 0}
								</p>
							</span>

							<Form.Item
								name={metric.key}
								valuePropName="checked"
								initialValue={metric.active}
								noStyle
							>
								<Switch disabled={disabledSwitches[metric.key]} />
							</Form.Item>
						</div>
					))}
				</div>
				<div className={styles["metrics"]}>
					<CustomButton type="default" className={styles["metrics-buttons"]} onClick={onClose}>
						Cancel
					</CustomButton>
					<CustomButton
						type="primary"
						className={styles["metrics-buttons"]}
						onClick={handleSave}
						loading={loading}
					>
						Save
					</CustomButton>
				</div>
			</Form>
		</div>
	);
};

export default CustomizeToday;
