import React, { useEffect, useMemo } from "react";

import { ReactComponent as CheckIcon } from "../../../assets/images/task-check.svg";
import { ReactComponent as SkippedIcon } from "../../../assets/images/dash-icon.svg";
import AvatarIcon from "../../../components/avatar-icon";
import { ITask } from "../../../utils/common-interfaces";

import styles from "./leftSteps.module.scss";

interface IProps {
	tasks: ITask[];
	currentStep: string;
	setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
	handleStepChange: (key: string, type: "self_check" | "custom" | "integration") => void;
	onboardingTasks: any;
}

const LeftSteps = (props: IProps) => {
	const { tasks, currentStep, setCurrentStep, handleStepChange, onboardingTasks } = props;

	useEffect(() => {
		const firstIncompleteTask = tasks.find((task) => {
			const flag = !isCompletedTask(task);

			return flag;
		});
		if (firstIncompleteTask) {
			setCurrentStep(firstIncompleteTask.label);
		}
	}, [tasks]);

	const isActiveTask = (key: string) => {
		return currentStep === key;
	};

	const isCompletedTask = useMemo(
		() => (task: ITask) => {
			if (task && task.tasks_responses?.length > 0) return true;
			if (
				task &&
				task.sub_tasks.length > 0 &&
				task.sub_tasks.every((subtask) => subtask?.tasks_responses?.length > 0)
			)
				return true;
			return false;
		},
		[tasks]
	);

	const isDisabledTask = (task: ITask, index: number) => {
		const firstIncompleteIndex = tasks.findIndex((task) => !isCompletedTask(task));
		return firstIncompleteIndex;
	};

	const isTaskSkipped = (task: ITask, index: number) => {
    return (
        task?.tasks_responses?.[0]?.is_skipped ?? false
    );
};


	return (
		<div className={styles.wrapper}>
			{tasks.map((task, index) => {
				return (
					<div key={task.label} className={`${styles.task} cursor-disabled`}>
						<AvatarIcon
							icon={
								isTaskSkipped(task, index) ? (
									<SkippedIcon />
								) : isCompletedTask(task) ? (
									<CheckIcon />
								) : undefined
							}
							firstName={isCompletedTask(task) ? "" : (index + 1).toString()}
							color={"#d9d9d9"}
							wrapperClassName={styles["avatar-wrapper"]}
							contentClassName={`${styles.avatar} ${isTaskSkipped(task, index) ? styles.skipped : isCompletedTask(task) ? styles.completed : isActiveTask(task.label) ? styles.active : ""}`}
						/>

						<div
							className={`${styles["task-title"]} ${isActiveTask(task.label) ? styles.active : ""}`}
						>
							{task.title}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default LeftSteps;
