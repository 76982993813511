import { Form, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { api } from "../../../../api";
import CustomButton from "../../../../components/button";
import CustomSteps from "../../../../components/custom-steps";
import { useCurrentUser } from "../../../../hooks";
import { IAddress, ITask } from "../../../../utils/common-interfaces";
import { getApiErrorMsg } from "../../../../utils/object-util";
import { deleteKeysFromObject } from "../../../../utils/function-utils";

import AboutYou from "./about-you-form";
import BankingAndTax from "./banking-and-tax-form";
import BasicInfo from "./basic-info-form";
import DocumentsUploadForm from "./documents-upload-form";
import SkillEvaluation from "./skill-evaluation-form";
import styles from "./buildProfile.module.scss";
interface IProps {
	task?: ITask;
	onSubmit: (values: any) => void;
	formID?: string;
}

const BuildProfile = (props: IProps) => {
	const { user } = useCurrentUser();
	const { task, formID, onSubmit } = props;
	const [activeStep, setActiveStep] = useState(0);
	const [addresses, setAddresses] = useState<IAddress[]>([]);

	const [steps, setSteps] = useState<
		{
			id: string;
			title: string;
		}[]
	>([]);
	const [stepComponent, setStepComponent] = useState<React.ReactNode>();
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (task)
			setSteps(
				task.sub_tasks.map((sub_task) => ({
					id: sub_task.id,
					title: sub_task.title,
				}))
			);
	}, [task]);

	useEffect(() => {
		addresses.forEach((address: any) => {
			if (address.id) {
				deleteKeysFromObject(address, [
					"id",
					"zip_code",
					"organisation_id",
					"candidate_id",
					"created_at",
					"updated_at",
					"teamMember_id",
				]);
			}
		});
	}, [addresses]);

	useEffect(() => {
		getUserDetails();
	}, []);

	useEffect(() => {
		handleStepComponent(activeStep);
	}, [activeStep]);

	useEffect(() => {
		const firstIncompleteSubTask = task?.sub_tasks.findIndex((subTask) => {
			return !subTask?.tasks_responses?.length;
		});
		setActiveStep(firstIncompleteSubTask ?? 0);
	}, [task]);

	const getUserDetails = () => {
		api
			.get({
				path: `/team-members/${user?.team_member_id}`,
				service: "job",
			})
			.then((response) => {
				const data = response.data.data;
				setAddresses(data.address);
				const personalAddress = data.address.find((el: any) => el.name === "personal");

				const professionalAddress = data.address.find((el: any) => el.name === "professional");

				const formattedEducationDetails = data.education.map((set: any) => {
					return {
						...set,
						completion_year: dayjs(set.completion_year),
						joining_year: dayjs(set.joining_year),
					};
				});

				form.setFieldsValue({
					first_name: data.first_name,
					last_name: data.last_name,
					mobile_no: data.mobile_no,
					gender: data.gender,
					date_of_birth: data.date_of_birth,
					bank_ifsc_code: data.bank_ifsc_code,
					bank_name: data.bank_name,
					bank_branch: data.bank_branch,
					bank_account_number: data.bank_account_number,
					about_me: data.about_me,
					favourite_music: data.favourite_music,
					favourite_shows: data.favourite_shows,
					marital_status: data.marital_status,
					experience: data.experience,
					company: data.company,
					job_title: data.job_title,
					address: {
						...(professionalAddress
							? {
									professional: {
										address_line: professionalAddress.address_line,
										city: professionalAddress.city,
										state: professionalAddress.state,
										country: professionalAddress.country,
									},
								}
							: {}),
						...(personalAddress
							? {
									personal: {
										address_line: personalAddress.address_line,
										city: personalAddress.city,
										state: personalAddress.state,
										country: personalAddress.country,
									},
								}
							: {}),
					},
					education: formattedEducationDetails,
					skills_evaluate: data.skills_evaluate,
				});
			})
			.catch((error) =>
				message.error({ content: getApiErrorMsg(error), key: "team-member-error", duration: 3 })
			);
	};

	const handleStepComponent = (step: number) => {
		switch (step) {
			case 0:
				setStepComponent(<BasicInfo form={form} />);
				break;
			case 1:
				setStepComponent(<SkillEvaluation />);
				break;
			case 2:
				setStepComponent(<BankingAndTax form={form} />);
				break;
			case 3:
				setStepComponent(<AboutYou form={form} />);
				break;
			case 4:
				setStepComponent(<DocumentsUploadForm form={form} />);
				break;
			default:
				break;
		}
	};

	const formatAddress = (address: any) => {
		const formattedAddress: {
			name: string;
			address_line: string;
			country: string;
			state: string;
			city: string;
		}[] = addresses;

		Object.entries(address[0]).forEach((entry) => {
			const key = entry[0];

			const address: any = entry[1];

			const findIndex = addresses.findIndex((add) => add.name === key);

			if (findIndex > -1) {
				formattedAddress[findIndex].address_line = address.address_line;
				formattedAddress[findIndex].country = address.country;
				formattedAddress[findIndex].city = address.city;
				formattedAddress[findIndex].state = address.state;
			} else if (address) {
				formattedAddress.push({
					name: key,
					address_line: address.address_line,
					country: address.country,
					city: address.city,
					state: address.state,
				});
			}
		});
		return formattedAddress;
	};

	const updateTeamMember = (values: any) => {
		setIsLoading(true);
		api
			.put({
				path: `/team-members/${user?.team_member_id}`,
				service: "job",
				formdata: {
					...values,
					...(values.address && { address: formatAddress([values.address]) }),
					...(values.date_of_birth && {
						date_of_birth: dayjs(values.date_of_birth).format("DD-MM-YYYY"),
					}),
					...(values.education && {
						education: values.education?.map((el: any) => ({
							university_id: el.university_id,
							degree_id: el.degree_id,
							specialisation_id: el.specialisation_id,
							cgpa: el.cgpa,
							joining_year: dayjs(el.joining_year).format("DD-MM-YYYY"),
							completion_year: dayjs(el.completion_year).format("DD-MM-YYYY"),
						})),
					}),
					skills: undefined,
				},
			})
			.then(() => subtaskCompletion(values))
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "team-member-exit",
					duration: 3,
				});
			})
			.finally(() => setIsLoading(false));
	};

	const uploadDocuments = (values: any) => {
		setIsLoading(true);
		const formData = Object.keys(values)
			.map((key) => ({
				name: key,
				url: values[key],
			}))
			.filter((el) => el.url);

		api
			.put({
				path: "/documents/bulk",
				service: "job",
				formdata: { documents: formData },
			})
			.then(() => subtaskCompletion(values))
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "team-member-exit",
					duration: 3,
				});
			})
			.finally(() => setIsLoading(false));
	};

	const subtaskCompletion = (values: any) => {
		setIsLoading(true);
		api
			.put({
				path: `/forms/${formID}/responses`,
				service: "job",
				formdata: {
					team_member_id: user?.team_member_id,
					response: [
						{
							task_id: steps[activeStep]?.id,
							response: "yes",
						},
					],
				},
			})
			.then(() => {
				if (activeStep < steps.length - 1) {
					message.success({
						content: "Details confirmed successfully",
						key: "team-member-exit",
						duration: 3,
					});
				}
				if (activeStep === steps.length - 1) {
					onSubmit({ id: task?.id });
				}
				setActiveStep(task?.sub_tasks.length === activeStep ? 0 : activeStep + 1);
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "team-member-exit",
					duration: 3,
				});
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div className={styles.wrapper}>
			<CustomSteps
				steps={steps.map((step) => step.title)}
				activeStep={activeStep}
				setActiveStep={setActiveStep}
			/>
			<Form
				id="build-profile-form"
				name="build-profile-form"
				form={form}
				initialValues={{}}
				labelCol={{ span: 24 }}
				onFinish={(values) => {
					if (activeStep === 4) {
						uploadDocuments(values);
					} else {
						updateTeamMember(values);
					}
				}}
				validateTrigger={["onBlur", "onChange"]}
				className={styles["candidate-shortlist-form"]}
			>
				{stepComponent}
				<Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
					<CustomButton loading={isLoading} type="primary" htmlType="submit">
						Next
					</CustomButton>
				</Form.Item>
			</Form>
		</div>
	);
};

export default BuildProfile;
