import styles from "./heading.module.scss";

interface IProps {
  title: string;
  titleClassName?: string;
  titleStyle?: React.CSSProperties;
}

const Heading = ({ title, titleClassName, titleStyle }: IProps) => {
  return (
    <h4
      className={`${styles["heading"]}
       ${titleClassName ?? ""}`}
      style={titleStyle || {}}
    >
      {title}
    </h4>
  );
};

export default Heading;
