import { useEffect, useRef, useState } from "react";
import { Table as AntdTable, PaginationProps } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";

import SearchIcon from "../../assets/images/search-icon.svg";
import { Input } from "../form-fields";

import styles from "./table.module.scss";

export interface IPagination extends PaginationProps {
	current: number;
	pageSize: number;
	total: number;
}

interface ITableProps {
	rows: any[];
	columns: ColumnsType<any>;
	pagination?: IPagination | boolean;
	rowKey: string | ((record: any) => string | number);
	showSearch?: boolean;
	onRow?: (
		record: any,
		index: number
	) => {
		onClick?: (event: React.MouseEvent<HTMLElement>) => void;
		onDoubleClick?: (event: React.MouseEvent<HTMLElement>) => void;
	};
	searchText?: string;
	loading?: boolean;
	bordered?: boolean;
	rightContent?: React.ReactNode;
	rowSelection?: any;
	rowClassName?: string | ((record: any, index: number, indent: number) => string);
	className?: string;
	locale?: { emptyText?: string | React.ReactNode | (() => React.ReactNode) };
	scroll?: { x?: number; y?: number };
	onChange?: TableProps<any>["onChange"];
	title?: TableProps<any>["title"];
	style?: React.CSSProperties;
	footer?: TableProps<any>["footer"];
	darkHeader?: boolean;
}

const Table = ({
	rows,
	columns,
	bordered,
	pagination,
	rowKey,
	showSearch,
	searchText,
	rowSelection,
	rowClassName,
	onRow,
	loading,
	rightContent,
	className,
	locale,
	onChange,
	style,
	footer,
	title,
	darkHeader = true,
}: ITableProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [searchedText, setSearchedText] = useState(searchText ?? "");

	useEffect(() => {
		setSearchedText(searchText ?? "");
	}, [searchText]);

	const tableExtraProps: any = {};

	if (pagination || pagination === false) {
		tableExtraProps.pagination = pagination;
		tableExtraProps.onChange = onChange;
	}

	if (locale) {
		tableExtraProps.locale = locale;
	}

	let filteredRows = [];
	if (searchedText) {
		filteredRows = rows.filter((rowEl) =>
			columns.find(
				(colEl: any) => !!rowEl[colEl.dataIndex]?.toLowerCase?.()?.includes?.(searchedText.trim())
			)
		);
	} else {
		filteredRows = rows;
	}

	const getWidth = (width: any) =>
		typeof width === "string" ? Number(width.replace("px", "")) : width || 0;

	const width = wrapperRef.current?.clientWidth ?? 0;
	const accumulatedWidth = (columns.reduce((acc, col) => {
		return {
			width: getWidth(acc.width) + getWidth(col.width),
		};
	})?.width ?? 0) as number;

	const finalWidth =
		(accumulatedWidth > width ? accumulatedWidth : width) - (bordered ? columns.length - 1 : 0);

	const checkIdType = (key: any) =>
		typeof key === "string" || typeof key === "number" ? String(key) : null;

	const getOnCell = (col: any, colIndex: number) => (record: any, rowIndex: number) => {
		const existingOnCell = col?.onCell ? col.onCell(record, rowIndex) : {};

		const id =
			existingOnCell?.id ??
			`${(checkIdType(col?.title) ?? checkIdType(col?.key) ?? `column-${colIndex}`)
				.toLowerCase()
				.replace(/\s+/g, "_")}-of-row-${rowIndex}`;

		return { ...existingOnCell, id };
	};

	return (
		<div ref={wrapperRef} className={styles["wrapper"]}>
			{(showSearch || rightContent) && (
				<div className={styles["top-panel"]}>
					<div className={styles["top-left-wrapper"]}>
						{showSearch && (
							<div className={styles["search-wrapper"]}>
								<img src={SearchIcon} alt="find-icon" className={styles["icon-img"]} />
								<Input
									placeholder="Search Team Members"
									className={styles["input-search"]}
									value={searchedText}
									onChange={(e) => setSearchedText(e.target.value)}
								/>
							</div>
						)}
					</div>
					{rightContent}
				</div>
			)}

			<AntdTable
				loading={loading}
				dataSource={filteredRows}
				columns={columns.map((col, colIndex) => ({
					...col,
					onCell: getOnCell(col, colIndex),
				}))}
				className={`${styles["table-wrapper"]} ${className ?? ""} ${darkHeader ? styles["dark-header"] : ""}`}
				rowKey={rowKey}
				rowSelection={rowSelection}
				scroll={{ x: finalWidth || 0 }}
				onRow={onRow}
				bordered={bordered}
				rowClassName={rowClassName}
				title={title}
				style={style}
				footer={footer}
				{...tableExtraProps}
			/>
		</div>
	);
};

export default Table;
