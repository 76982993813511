import ImageIcon from "../assets/images/image.svg";
import PDFIcon from "../assets/images/pdf.svg";
import WordIcon from "../assets/images/word.svg";
import XlsIcon from "../assets/images/sheet-icon.svg";
import CsvIcon from "../assets/images/csv-icon.svg"; 

export const fileIcon = (fileType: string) => {
	switch (fileType.toLowerCase()) {
		case "pdf":
			return <img src={PDFIcon} alt="PDF Icon" />;
		case "doc":
			return <img src={WordIcon} alt="Doc Icon" />;
		case "docx":
			return <img src={WordIcon} alt="Doc Icon" />;
		case "xls":
			return <img src={XlsIcon} alt="Excel Icon" />;
		case "xlsx":
			return <img src={XlsIcon} alt="Excel Icon" />;
		case "csv":
			return <img src={CsvIcon} alt="CSV Icon" />;
		case "png":
			return <img src={ImageIcon} alt="Image Icon" />;
		case "jpg":
			return <img src={ImageIcon} alt="Image Icon" />;
		case "jpeg":
			return <img src={ImageIcon} alt="Image Icon" />;
		case "gif":
			return <img src={ImageIcon} alt="Image Icon" />;
		case "svg":
			return <img src={ImageIcon} alt="Image Icon" />;
		default:
			return <></>;
	}
};
