import http from "./http";
import { PostPropsType, ApiPropsType } from "./interfaces";

const getServicePath = (service: string) => {
	switch (service) {
		case "auth":
			return process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
		case "job":
			return process.env.REACT_APP_JOB_SERVICE_BASE_URL;
		case "attendance":
			return process.env.REACT_APP_ATTENDANCE_SERVICE_BASE_URL;
		case "finance":
			return process.env.REACT_APP_FINANCE_SERVICE_BASE_URL;
		case "ai":
			return process.env.REACT_APP_AIASSISTANT_SERVICE_BASE_URL;
		default:
			return "";
	}
};

function get({ path, params = {}, service = "", config = {} }: ApiPropsType) {
	const finalPath = `${getServicePath(service)}${path}`;

	return http.get(finalPath, { ...config, params });
}

function post({ path, formdata = {}, params = {}, service = "", config = {} }: PostPropsType) {
	const finalPath = `${getServicePath(service)}${path}`;
	return http.post(finalPath, formdata, { ...config, params });
}

function patch({ path, formdata = {}, params = {}, service = "", config = {} }: PostPropsType) {
	const finalPath = `${getServicePath(service)}${path}`;

	return http.patch(finalPath, formdata, { ...config, params });
}

function put({ path, formdata = {}, params = {}, service = "", config = {} }: PostPropsType) {
	const finalPath = `${getServicePath(service)}${path}`;

	return http.put(finalPath, formdata, { ...config, params });
}

function remove({ path, params = {}, service = "", config = {} }: ApiPropsType) {
	const finalPath = `${getServicePath(service)}${path}`;

	return http.delete(finalPath, { ...config, params });
}

export const api = { get, post, patch, put, remove };
