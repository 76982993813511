import { createRef, useState } from "react";
import { Avatar, Dropdown, MenuProps, message } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useRolePermission, useCurrentUser } from "../../hooks";
import { IProfile } from "../../utils/common-interfaces";
import { getApiErrorMsg } from "../../utils/object-util";
import Upload, { RcFile, UploadProps } from "antd/lib/upload";
import { ReactComponent as EditIcon } from "../../assets/images/edit-slim.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { api } from "../../api";
import Spinner from "../spinner";
import CustomButton from "../button";

import styles from "./profileImage.module.scss";

interface EditableProps {
	editable: true;
	onUploadSuccess: (fileUrl: string | null) => void;
}

interface NonEditableProps {
	editable?: false;
	onUploadSuccess?: never;
}

type IProps = {
	image?: string | null;
	wrapperStyle?: React.CSSProperties;
	imageStyle?: React.CSSProperties;
	wrapperClassName?: string;
	teamMemberId?: string;
	size?: "small" | "medium" | "large";
} & (EditableProps | NonEditableProps);

const ProfileImage = (props: IProps) => {
	const {
		image,
		wrapperStyle,
		imageStyle,
		wrapperClassName,
		editable,
		size = "large",
		teamMemberId,
		onUploadSuccess,
	} = props;

	const uploadRef = createRef<any>();
	const { user } = useCurrentUser();
	const { canEdit } = useRolePermission("PEOPLE");

	const [uploading, setUploading] = useState(false);
	const [currentImage, setCurrentImage] = useState(image);

	const uploadImage = (file: RcFile) => {
		if (file.size >= 2 * 1024 * 1024) {
			message.error({
				content: "File too large! Please select a file under 2MB.",
				key: "uploadfiles",
				duration: 4,
			});
		} else {
			const formData = new FormData();
			formData.append("file", file);
			setUploading(true);
			api
				.post({
					path: "/upload",
					service: "job",
					formdata: formData,
				})
				.then((response) => {
					if (response.data?.data?.file) {
						if (onUploadSuccess) {
							setCurrentImage(response.data.data.file);
							onUploadSuccess(response.data.data.file);
						}
					}
				})
				.catch((error) => {
					message.error({
						content: getApiErrorMsg(error),
						key: "uploadimage",
						duration: 3,
					});
				})
				.finally(() => setUploading(false));
		}
	};

	const uploadProps: UploadProps = {
		name: "file",
		fileList: [],
		maxCount: 1,
		showUploadList: false,
		accept: "image/*",
		beforeUpload: (file: RcFile) => {
			const isImage = file.type.startsWith("image/");
			if (!isImage) {
				message.error(`${file.name} is not an image file`);
				return false;
			}
			if (isImage) {
				uploadImage(file);
			}
		},
		customRequest: () => null,
	};

	const menuItems: MenuProps["items"] = [
		{
			label: (
				<Upload {...uploadProps} ref={uploadRef}>
					<p className="flex-center-center" style={{ gap: 6 }}>
						<EditIcon /> Change
					</p>
				</Upload>
			),
			key: "change",
		},
		...(image
			? [
					{
						label: (
							<p className="flex-center-center" style={{ gap: 6 }}>
								<DeleteIcon style={{ width: 15 }} /> Remove
							</p>
						),
						key: "remove",
					},
				]
			: []),
	];

	return (
		<div
			className={`${styles["profile-image-wrapper"]} ${
				size === "small" ? styles["small"] : ""
			} ${size === "medium" ? styles["medium"] : ""} ${wrapperClassName ?? ""}`}
			style={wrapperStyle ?? {}}
		>
			{uploading ? (
				<div className={styles["loading-overlay"]}>
					<Spinner />
				</div>
			) : null}
			{currentImage ? (
				<img
					src={currentImage}
					alt="profile"
					style={imageStyle ?? {}}
					className={styles["profile-image"]}
				/>
			) : (
				<Avatar
					size={size === "small" ? 36 : size === "medium" ? 61 : 120}
					icon={<UserOutlined />}
					className={styles["profile-image"]}
				/>
			)}
			{editable &&
				(uploading ? (
					<div
						className={`${styles["edit-icon"]} ${
							size === "small" ? styles["small"] : ""
						} ${size === "medium" ? styles["medium"] : ""}`}
					>
						<Spinner size="small" wrapperStyle={{ padding: 0 }} />
					</div>
				) : !image ? (
					<Upload {...uploadProps} ref={uploadRef}>
						{(user?.team_member_id === teamMemberId || canEdit) && (
							<CustomButton
								type="default"
								size="small"
								className={`${styles["edit-icon"]} ${
									size === "small" ? styles["small"] : ""
								} ${size === "medium" ? styles["medium"] : ""}`}
							>
								<EditIcon />
							</CustomButton>
						)}
					</Upload>
				) : (
					teamMemberId === user?.team_member_id && (
						<Dropdown
							menu={{
								items: menuItems,
								onClick: ({ key }) => {
									if (key === "change") {
										uploadRef.current?.onClick?.();
									} else if (key === "remove") {
										setCurrentImage(null);
										if (onUploadSuccess) {
											onUploadSuccess(null);
										}
									}
								},
							}}
							trigger={["click"]}
						>
							<CustomButton
								type="default"
								size="small"
								className={`${styles["edit-icon"]} ${
									size === "small" ? styles["small"] : ""
								} ${size === "medium" ? styles["medium"] : ""}`}
							>
								<EditIcon />
							</CustomButton>
						</Dropdown>
					)
				))}
		</div>
	);
};

export default ProfileImage;
