import React, { memo } from "react";
import { Divider } from "antd";

import Badges from "../badges";
import { ReactComponent as StepIcon } from "../../../assets/images/stepIcon.svg";
import { ReactComponent as StepChampion } from "../../../assets/images/stepChampion.svg";
import { ReactComponent as SleepIcon } from "../../../assets/images/sleepIcon.svg";
import { ReactComponent as EarlyBirdIcon } from "../../../assets/images/early-bird-icon.svg";
import { ReactComponent as HydrationHero } from "../../../assets/images/hydration-hero.svg";
import { ReactComponent as WorkoutIcon } from "../../../assets/images/workout-worrior.svg";
import { ReactComponent as NutritionIcon } from "../../../assets/images/nutrition-ninja.svg";
import { ReactComponent as CardioIcon } from "../../../assets/images/cardio-champion.svg";
import { ReactComponent as GoalGetter } from "../../../assets/images/goal-getter.svg";
import { isNotEmptyArray } from "../../../utils/type-util";

import styles from "./knowmore.module.scss";

interface KnowMoreProps {
	badgesArray?: {
		id: string;
		type: string;
		tenant_id: string;
		badges: {
			id: string;
			teamMemberId: string;
			badge_id: string;
			tenant_id: string;
			assigned_at: string;
		}[];
		count: number;
	}[];
}

const KnowMore = memo(({ badgesArray }: KnowMoreProps) => {
	const badgeIcons: { [key: string]: JSX.Element } = {
    Step_Champion: <StepChampion />,
    Step_Streak: <StepIcon />,
    Nutrition_Ninja: <NutritionIcon />,
    Cardio_Champion: <CardioIcon />,
    Hydration_Hero: <HydrationHero />,
    Early_Bird: <EarlyBirdIcon />,
    Workout_Warrior: <WorkoutIcon />,
    Sleep_Superstar: <SleepIcon />,
    Goal_Getter: <GoalGetter />,
	};

	const badgeDescriptions: { [key: string]: string } = {
    Step_Champion: "Complete 10,000 steps every day for a month.",
    Step_Streak: "Complete 10,000 steps every day for a week.",
    Nutrition_Ninja: "Log food intake for 7 consecutive days.",
    Cardio_Champion: "Reach your target heart rate zone for at least 30 minutes in a workout.",
    Hydration_Hero: "Log daily water intake for 5 days.",
    Early_Bird: "Wake up before 6 AM and log a workout.",
    Workout_Warrior: "Complete 5 workouts in a week.",
    Sleep_Superstar: "Achieve 7 hours of sleep in a night.",
    Goal_Getter: "Achieve a 30 Day Goal!.",
  };
	return (
		<div className={styles["badges-wrapper"]}>
			{isNotEmptyArray(badgesArray) ? (
				badgesArray?.map((badge, index) => (
					<>
						<Badges
							key={badge.type}
              name={badge.type.replace(/_/g, " ")}
              badge={badgeIcons[badge.type] ?? null}
              badgeClassName={badge.count > 0 ? styles["color-badge"] : ""}
             description={badgeDescriptions[badge.type] ?? "No description available."}
							wrapperClassName={styles["wrapperClassName"]}
						/>
						{index % 3 !== 2 && <Divider type="vertical" style={{ height: "unset" }} />}
					</>
				))
			) : (
				<div>No badges available.</div>
			)}
		</div>
	);
});

export default KnowMore;
