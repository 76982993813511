import { Flex } from "antd";
import dayjs, { Dayjs } from "dayjs";

import Section from "../health-section";
import { BaseCard } from "../../../components/base-card";
import {IActivityLog,IWeeklyActivity} from "../../../constants/user-interfaces";
import { ReactComponent as Check } from "../../../assets/images/checkIcon.svg";

import styles from "./week-activity.module.scss";

interface IProps {
	weekly_activities?: IWeeklyActivity[];
	currentDay: Dayjs;
}

const WeekActivity = ({ currentDay, weekly_activities }: IProps) => {
	const startOfWeek = dayjs().startOf("week");
	const daysOfWeek = Array.from(Array(7).keys()).map((day) => dayjs(startOfWeek).add(day, "day"));

	const activitiesByDay: { [key: string]: IActivityLog[] } = {};

	daysOfWeek.forEach((day) => {
		const dayString = day.format("YYYY-MM-DD");
		activitiesByDay[dayString] = [];
	});

	weekly_activities?.forEach((entry) => {
		const entryDate = dayjs(entry.date).format("YYYY-MM-DD");
		if (activitiesByDay[entryDate] && entry.activity_log) {
			activitiesByDay[entryDate] = entry.activity_log;
		}
	});

	return (
		<Section 
		    leftSideHeading="This week's activity">
			<BaseCard
				footertTopBorder={false}
				wrapperClassName={styles["week"]}
				footerWrapperStyle={
					weekly_activities?.some((value) => value.activity_log && value.activity_log.length > 0)
						? {
								border: "1px solid red",
							}
						: undefined
				}
				footer={
					weekly_activities?.some((value) => value.activity_log && value.activity_log.length > 0)
						? weekly_activities.map(
								(activity) =>
									activity?.activity_log &&
									activity?.activity_log.length > 0 && (
										<>
											<Flex style={{ padding: "1rem 0 0.5rem 0" }} justify="space-between">
												<p className={styles["heading"]}>Strength Training</p>
												<p className={styles["sub-heading"]}>
													{dayjs(activity.date).format("dddd")}
												</p>
											</Flex>
											{activity.activity_log?.map((log) => (
												<Flex gap={16} key={log.id}>
													<p className={styles["strength-values"]}>{log.calories} CAL</p>
													<p className={styles["strength-values"]}>{log.duration / 60000} MINS</p>
												</Flex>
											))}
										</>
									)
							)
						: undefined
				}
			>
				<div className={styles["days-wrapper"]}>
					{daysOfWeek.map((day, index) => (
						<div key={index} className={`${styles["week-day"]} `}>
							<p
								className={`${styles["days"]} ${day.isSame(currentDay, "day") ? styles["highlighted"] : ""}  ${activitiesByDay[day.format("YYYY-MM-DD")].length > 0 ? styles["highlighted-day"] : ""}`}
							/>
							<span>{day.format("dd")}</span>
							{activitiesByDay[day.format("YYYY-MM-DD")].length > 0 && (
								<span className={styles["tick"]}>
									<Check />
								</span>
							)}
						</div>
					))}
				</div>
			</BaseCard>
		</Section>
	);
};
export default WeekActivity;
