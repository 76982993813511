import  Drawer  from '../../../components/drawer/new';

import styles from "./custom-drawer.module.scss";

interface IProps {
  visible: boolean;
  title: string;
  onClose: () => void;
  content: React.ReactNode;
  className?:string;
}

const CustomDrawer = ({ visible, title, onClose, content,className }:IProps) => {
  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={600}
      className={`${styles["custom-drawer"]} ${className}`}
    >
      {content}
    </Drawer>
  );
};

export default CustomDrawer;
