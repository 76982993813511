
import { useCurrentUser } from "../../../hooks";
import { ITask } from "../../../utils/common-interfaces";
import { ReactComponent as SkipIcon } from "../../../assets/images/skip-forward.svg";
import CustomButton from "../../../components/button";

import PassTasks from "./pass-tasks";
import styles from "./tasks.module.scss";

interface IProps {
	selectedTaskKey: string;
	onboardingTasks: any;
	task?: ITask;
	formID: string;
	skipTask:(values: any, successCallback?: () => void) => void
}

const Tasks = (props: IProps) => {
	const { selectedTaskKey, onboardingTasks,skipTask,task} = props;

	const { user } = useCurrentUser();
	const selectedTask = onboardingTasks[selectedTaskKey];



	return (
		<div className={styles.wrapper}>
			<PassTasks
				greetingIcon={selectedTask?.rightContent?.greetingIcon}
				title={selectedTask?.rightContent?.title}
				description={selectedTask?.rightContent?.description}
				btnText={selectedTask?.rightContent?.btnText}
				btnIcon={selectedTask?.rightContent?.btnIcon}
				completed={false}
				onBtnClick={selectedTask?.rightContent?.onBtnClick}
				component={selectedTask?.rightContent?.component}
				task={task}
			/>
			<div className={styles["btn-wrapper"]}>
				<CustomButton
					type="text"
					icon={<SkipIcon />}
					style={{ color: "#434343" }}
					onClick={()=>skipTask({is_skipped:true,id:task?.id}, undefined)}
				>
					Skip this step
				</CustomButton>
			</div>
		</div>
	);
};

export default Tasks;
