import React from "react";

import OnboardingImage from "../../../assets/images/team.png";
import PrivateHeader from "../../private-header";

import styles from "./onboardingLayout.module.scss";

interface IOnboardingLayoutProps {
	leftSideContent?: React.ReactNode;
	rightSideContent?: React.ReactNode;
  showImage?:boolean;
	imageUrl?: string;
}

const OnboardingLayout = ({
	leftSideContent,
	rightSideContent,
	imageUrl,
  showImage =true
}: IOnboardingLayoutProps) => {
	return (
		<>
			<PrivateHeader />
			<div className={styles["onboarding-layout"]}>
				<div className={styles["left-side"]}>
					{showImage && <img src={imageUrl ?? OnboardingImage} alt="onboarding-image" />}
					{leftSideContent}
				</div>
				<div className={styles["right-side"]}>{rightSideContent}</div>
			</div>
		</>
	);
};

export default OnboardingLayout;
