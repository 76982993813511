import { ReactElement } from "react";

import HeaderTwo from "../../../components/header-two";

import styles from "./section.module.scss";
interface IProps {
	rightSideContent?: ReactElement;
	rightSideHeading?: string;
	leftSideHeading?: string;
	rightSideSubHeading?: string;
	leftSideSubHeading?: string;
	leftSideContent?: ReactElement;
	children?: ReactElement;
	wrapperClassName?: string;
	childrenClassName?: string;
	childrenStyle?: React.CSSProperties;
}

const Section = ({
	rightSideContent,
	rightSideHeading,
	rightSideSubHeading,
	leftSideContent,
	leftSideHeading,
	leftSideSubHeading,
	children,
	wrapperClassName,
	childrenClassName,
	childrenStyle,
}: IProps) => {
	return (
		<>
			<HeaderTwo
				rightSideContent={
					<>
						{rightSideHeading ? <h4>{rightSideHeading}</h4> : null}
						{rightSideSubHeading ? (
							<p className={styles["sub-heading"]}>{rightSideSubHeading}</p>
						) : null}
						{rightSideContent}
					</>
				}
				leftSideContent={
					<>
						{leftSideHeading ? <h4>{leftSideHeading}</h4> : null}
						{leftSideSubHeading ? (
							<p className={styles["sub-heading"]}>{leftSideSubHeading}</p>
						) : null}
						{leftSideContent}
					</>
				}
				wrapperClassName={`${styles["header-wrapper"]} ${wrapperClassName ?? ""}`}
				leftSideContentWrapperClass={styles["title"]}
			/>
			<div className={`${styles["data-cards"]}  ${childrenClassName ?? ""}`} style={childrenStyle}>
				{children}
			</div>
		</>
	);
};
export default Section;
